import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { formatCurrency } from "../../../utils/commonUtils";

// Define styles
const styles = StyleSheet.create({
  // Title Page Styles
  centeredPage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  logoCentered: {
    width: 300,
    height: "auto",
    marginBottom: 30,
  },

  reportTitle: {
    fontSize: 40,
    fontFamily: "Helvetica-Bold",
    marginBottom: 15,
  },

  dateText: {
    fontSize: 20,
    color: "#555",
  },
  
  // Page styles
  page: {
    flexDirection: "column",
    padding: 15,
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
  },
  pageBorder: {
    position: "absolute",
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 5,
  },
  content: {
    flex: 1,
    padding: 10,
  },

  // Header styles
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderColor: "#ddd",
    width: "100%",
  },
  logo: {
    width: 60,
    height: "auto",
  },
  headerTitle: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    color: "#333",
    textAlign: "center",
    flex: 1,
  },
  headerData: {
    fontSize: 7,
    color: "#000",
    textAlign: "right",
  },

  // Table styles
  table: {
    flexDirection: "column",
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: "#ccc",
    // minHeight: 15,
    height: "auto",
    maxHeight: "auto",
    minHeight: "auto"
  },
  tableHeaderRow: {
    backgroundColor: "#007BFF",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#004a99",
  },
  tableHeaderCell: {
    flex: 1,
    fontSize: 8,
    padding: 6,
    textAlign: "center",
    color: "#fff",
    // borderRightWidth: 0.5,
    // borderLeftWidth: 0.5,
    borderColor: "#004a99",
    fontFamily: "Helvetica-Bold"
  },
  tableCell: {
    flex: 1,
    fontSize: 8,
    padding: 4,
    // borderRightWidth: 0.5,
    // borderLeftWidth: 0.5,
    borderColor: "#ccc",
  },

  // Chart styles
  chartGridContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 15,
    gap: 10,
  },
  chartContainer: {
    width: "48%",
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    height: '230px'
  },
  chartImage: {
    width: "100%",
    height: "auto",
    maxHeight: "100%"
  },

  // Left container styles
  leftContainer: {
    width: "47%",
    borderRadius: 5,
    marginBottom: 15,
  },
  labelValueContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderColor: "#ddd",
    paddingBottom: 5,
    marginBottom: 5,
    width: "100%",
  },
  label: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#333",
  },
  value: {
    fontSize: 9,
    color: "#666",
  },

  // Section title styles
  sectionTitle: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    marginBottom: 7,
  },
});

// Table component
const TableComponent = ({ columns, data }) => {
  // Function to calculate totals for specified columns
  const calculateColumnTotals = () => {
    const totals = { id: "TOTAL_ROW" }; // Explicitly set an identifier for the TOTAL row
    let hasTotals = false;

    columns.forEach((col) => {
      if (col.total) {
        const totalValue = data.reduce((sum, row) => {
          const rawValue = row[col.dataField];
          const numericValue = rawValue ? parseFloat(rawValue.replace(/,/g, "")) : 0;
          return sum + numericValue;
        }, 0);
        const Rawtotal = totalValue.toFixed(2)
        totals[col.dataField] = formatCurrency(Rawtotal); // Format total to 2 decimal places
        hasTotals = true;
      } else if (col.TotalText) {
        totals[col.dataField] = "TOTAL";
      } else {
        totals[col.dataField] = "";
      }
    });

    console.log("Total Row Calculated:", totals);
    return hasTotals ? totals : null;
  };

  // Calculate the total row and append it to the data if totals exist
  const totalRow = calculateColumnTotals();
  console.log("Total Row:", totalRow);

  const tableData = totalRow ? [...data, totalRow] : data;
  console.log("Table Data with Totals:", tableData);

  // Custom styling for rows
  const getRowStyle = (row, rowIndex) => {
    if (row.id === "TOTAL_ROW") {
      return { backgroundColor: "#e0e0e0", fontWeight: "bolder",height: 'auto', fontSize: 10, padding: 4}; // Apply bold styling to TOTAL row
    }
  };

  // Custom formatting for cells
  const formatCell = (cell, col, row) => {
    if (row.id === "TOTAL_ROW") {
      if (col.total) {
        return cell ? cell : "";
      }
      if (col.TotalText) {
        return cell;
      }
    }
    return cell || "";
  };

  return (
    <View style={styles.table}>
      {/* Table Header */}
      <View style={[styles.tableRow, styles.tableHeaderRow]} wrap={true} fixed>
        {columns.map((col, colIdx) => (
          <Text key={colIdx} style={styles.tableHeaderCell}>
            {col.text}
          </Text>
        ))}
      </View>

      {/* Table Rows */}
      {tableData.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[styles.tableRow, getRowStyle(row, rowIndex)]}
          wrap={false} // Prevent rows from breaking across pages
        >
          {columns.map((col, colIdx) => (
            <Text
              key={colIdx}
              style={[
                styles.tableCell,
                colIdx === 0 ? { textAlign: "left" } : { textAlign: "right" },
              ]}
            >
              {formatCell(row[col.dataField], col, row)}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const formatDate = () => {
  const date = new Date();
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

// Chart rendering function
const renderCharts = (data) => {
  if (!data || data.length === 0) return null; // Don't render if no data

  return (
    <View style={styles.chartGridContainer}>
      {data.map((chart, idx) => (
        <View key={idx} style={styles.chartContainer}>
          {chart.chartImgSrc ? (
            <Image src={chart.chartImgSrc} style={styles.chartImage} />
          ) : (
            <Text style={{ fontSize: 8, color: "#999" }}>
              Chart image not available
            </Text>
          )}
        </View>
      ))}
    </View>
  );
};

// Main PDF component
const Pdf_GenerateHelper = ({ pageConfigurations, companyLogo }) => {
  // Filter out blank pages except for the first one
  const filteredPages = pageConfigurations.filter((config, index) => {
    if (config.pageNumber === 0) return true; // Keep the first page empty
    return (
      (config.headerObjValue && config.headerObjValue.length > 0) ||
      (config.tableColumns && config.tableColumns.length > 0) ||
      (config.tableData && config.tableData.length > 0) ||
      (config.data && config.data.length > 0)
    );
  });

  return (
    <Document>
      {filteredPages.map((config, index) => (
        <Page key={index} size="A4"  orientation="landscape" style={styles.page}>
          <View style={styles.pageBorder} fixed></View>
          <View style={styles.content}>
            {/* Ensure the first page is empty */}
            {config.pageNumber === 0 ? (
              <View style={styles.centeredPage}>
              {companyLogo && <Image src={companyLogo} style={styles.logoCentered} />}
              <Text style={styles.reportTitle}>Summary Report</Text>
              <Text style={styles.dateText}>{formatDate()}</Text>
            </View>
            ) : (
              <>
                {/* Header Section */}
                <View style={styles.header} fixed>
                  {companyLogo && <Image src={companyLogo} style={styles.logo} />}
                  <Text style={styles.headerTitle}>{config.title || "Report"}</Text>
                    <Text style={styles.headerData}>
                      <Text style={{fontFamily:"Helvetica-Bold"}}>Data reported as of:     </Text>
                      <Text>{formatDate()}</Text>
                    </Text>
                </View>

                {/* Financial Overview and Charts */}
                <View style={{ display: "flex", flexDirection: "row", width: "100%", gap: 15 }}>
                  {config.headerObjValue && config.headerObjValue.length > 0 ? (
                    <View style={{ display: "flex", flexDirection: "column-reverse", width: "100%" }}>
                      <View style={styles.leftContainer}>
                        <Text style={styles.sectionTitle}>Financial Overview:</Text>
                        {config.headerObjValue.map((item, index) => (
                          <View key={index} style={styles.labelValueContainer}>
                            <Text style={styles.label}>{item.label}</Text>
                            <Text style={styles.value}>{item.value}</Text>
                          </View>
                        ))}
                      </View>
                      {renderCharts(config.data)}
                    </View>
                  ) : (
                    renderCharts(config.data)
                  )}
                </View>

                {/* Table Section */}
                {config.tableColumns?.length > 0 && config.tableData?.length > 0 && (
                  <>
                    <Text style={styles.sectionTitle}>Summary List</Text>
                    <TableComponent
                      columns={config.tableColumns}
                      data={config.tableData}
                    />
                  </>
                )}
              </>
            )}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default Pdf_GenerateHelper;