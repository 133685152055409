import React, { useState, useEffect, useContext } from 'react';
import Select from "react-select";
import { PageLoader } from '../../Context/PageLoader';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { get, post, put } from '../../api';
import {
  GET_INVESTMENTS,
  GET_REAL_ESTATE_HOLDINGS,
  GET_REAL_ESTATE_INVESTMENTS,
  GET_ALL_OPERATING_BUSINESS,
  GET_PERSONAL_ASSETS,
  GET_USER_PERSONAL_INFORMATION,
  GET_NET_WORTH_DATA,
  GET_MINERAL_ASSETS,
  GET_REAL_ESTATE_HOLDINGS_WITH_IE,
  GET_CASH_VALUE_BY_IDS,
  CREATE_TRUSTEE_INFORMATION,
  GET_MANAGER_LIST
} from '../../api/endpoints';
import { SelectField } from '../../components/Forms/FormFields';
import { UserPermissionStore } from '../../Store/UserPermission';
import { checkPermission } from '../../utils/commonUtils';
import Pdf_ReportGenerator from './Pdf_ReportGenerator';
let startRow = null;
let endRow = null;
let tableEndRow = null;
let reportData = [];

const config = {
  "tableStartRow": 50,
  "individualInformation": [
    "C6;userName",
    "C7;userAddress",
    "C9;userPhone",
    "F23;formula=SUM(F15:F16)",
    "F32;formula=SUM(F24,F26,F27)",
    "F33;formula=SUM(F23,F32)",
    "F42;formula=SUM(F37:F40)",
    "P32;formula=SUM(P24:P30)",
    "P33;formula=F33-P32",
    "F37;cashFlow",
    "P26;realEstateMortgageDebt",
    "P42;formula=SUM(P37:P40)"
  ],
  "liquidAssets": {
    "order": 1,
    "headerCount": 1,
    "columnCount": 1,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;custodian", "D;productType", "F;investmentName", "J;currentValue;currency"],
    "footerFormula": ["J;formula=SUM(J<<startRow>>:L<<endRow>>);reference=F15", "M;formula=SUM(M<<startRow>>:M<<endRow>>);reference=P24"]
  },
  "lifeInsurance": {
    "order": 2,
    "headerCount": 1,
    "columnCount": 1,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;custodian", "D;investmentName", "F;cashSurrenderValue;currency"],
    "footerFormula": ["F;formula=SUM(F<<startRow>>:I<<endRow>>);reference=F16", "J;formula=SUM(J<<startRow>>:J<<endRow>>);reference=P25","M;formula=SUM(M<<startRow>>:M<<endRow>>)"]
  },
  "realEsateAssests": {
    "order": 3,
    "headerCount": 1,
    "columnCount": 2,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;holdingCompany", "D;interest;percentage", "E;cash;currency", "F;currentValuation;currency", "J;;formula=(D<<row>>)*E<<row>>+F<<row>>", "K;invAftDebt;currency", "L;;formula=(N<<row>>*M<<row>>)", "M;contingentLiability;percentage", "N;mortgageDebt;currency", "O;monthlyIncome;currency", "P;monthlyPayment;currency", "S;maturity"],
    "footerFormula": ["E;formula=SUM(E<<startRow>>:E<<endRow>>)", "F;formula=SUM(F<<startRow>>:F<<endRow>>)", "J;formula=SUM(J<<startRow>>:J<<endRow>>);reference=F24", "K;formula=SUM(K<<startRow>>:K<<endRow>>)", "L;formula=SUM(L<<startRow>>:L<<endRow>>)", "N;formula=SUM(N<<startRow>>:N<<endRow>>);reference=P37", "O;formula=SUM(O<<startRow>>:O<<endRow>>);reference=F38//multiply", "P;formula=SUM(P<<startRow>>:P<<endRow>>)"]
  },
  "privateEquityInvestments": {
    "order": 4,
    "headerCount": 1,
    "columnCount": 1,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;companyInvesting", "C;holdingCompany", "D;interest;percentage", "E;productType", "F;cash;currency", "G;equityDebtInvested;currency", "I;currentValuation;currency", "K;;formula=(D<<row>>)*F<<row>>+I<<row>>", "L;invAftDebt;currency", "M;distributionsNext12Months;currency", "P;monthlyPayment;currency", "Q;maturity"],
    "footerFormula": ["F;formula=SUM(F<<startRow>>:F<<endRow>>)", "G;formula=SUM(G<<startRow>>:G<<endRow>>)", "I;formula=SUM(I<<startRow>>:I<<endRow>>);reference=F25", "K;formula=SUM(K<<startRow>>:K<<endRow>>)", "L;formula=SUM(L<<startRow>>:L<<endRow>>)", "M;formula=SUM(M<<startRow>>:M<<endRow>>);reference=F39", "O;formula=SUM(O<<startRow>>:O<<endRow>>);reference=P27", "P;formula=SUM(P<<startRow>>:P<<endRow>>);reference=P38"]
  },
  "operatingBusinessRoyaltyIncome": {
    "order": 5,
    "headerCount": 1,
    "columnCount": 2,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;holdingCompany", "D;interest;percentage", "E;cash;currency", "F;currentValuation;currency", "I;currentValuation;currency", "J;;formula=(D<<row>>)*E<<row>>+F<<row>>", "K;invAftDebt;currency", "L;;formula=(N<<row>>*M<<row>>)", "M;contingentLiability;percentage", "N;entityLoanBalance;currency", "P;annualPayment;currency", "R;monthlyPayment;currency", "S;maturity"],
    "footerFormula": ["E;formula=SUM(E<<startRow>>:E<<endRow>>)", "F;formula=SUM(F<<startRow>>:I<<endRow>>)", "J;formula=SUM(J<<startRow>>:J<<endRow>>);reference=F26", "K;formula=SUM(K<<startRow>>:K<<endRow>>)", "L;formula=SUM(L<<startRow>>:L<<endRow>>);reference=P28", "N;formula=SUM(N<<startRow>>:N<<endRow>>)","O;formula=SUM(O<<startRow>>:O<<endRow>>);reference=F40", "P;formula=SUM(P<<startRow>>:P<<endRow>>);reference=P39", "R;formula=SUM(R<<startRow>>:R<<endRow>>)"]
  },
  "personalProperty": {
    "order": 6,
    "headerCount": 1,
    "columnCount": 2,
    "footerCount": 2,
    "emptyRows": 1,
    "valueColumns": ["B;description", "F;currentValue;currency", "H;loanAmount;currency"],
    "footerFormula": ["F;formula=SUM(F<<startRow>>:G<<endRow>>);reference=F27", "H;formula=SUM(H<<startRow>>:I<<endRow>>);reference=P29", "J;formula=SUM(J<<startRow>>:J<<endRow>>);reference=P40"]
  },
  "notes": {
    "order": 7,
    "headerCount": 1,
    "columnCount": 1,
    "footerCount": 3,
    "emptyRows": 1,
    "valueColumns": [],
    "footerFormula": ["F;formula=SUM(F<<startRow>>:F<<endRow>>);reference=F28,P30" ,"G;formula=SUM(G<<startRow>>:G<<endRow>>)"]
  }
}

const Excel_ReportGenerator = () => {
  const { loading, setLoading } = useContext(PageLoader);
  const [subscriberChoices, setSubscriberChoices] = useState([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState();
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    setLoading(true);
    authCheckAndUpdate()

    fetchManagerList()
  }, []);
  const authCheckAndUpdate = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
  };
  const fetchReportData = async () => {

    try {
      // console.log("selectedSubscriber  ::: ",selectedSubscriber);

      const requestUser = selectedSubscriber
        ? { user: selectedSubscriber }
        : {};

      const investmentsData = await get(GET_INVESTMENTS, requestUser);
      const REAL_ESTATE_ASSETS = await get(GET_REAL_ESTATE_HOLDINGS, requestUser);
      const PRIVATE_EQUITY_INVESTMENTS = await get(GET_REAL_ESTATE_INVESTMENTS, requestUser);
      const OPERATING_BUSINESS = await get(GET_ALL_OPERATING_BUSINESS, requestUser);
      const Royalty_Income = await get(GET_MINERAL_ASSETS, requestUser);
      const PERSONAL_ASSETS = await get(GET_PERSONAL_ASSETS, requestUser);
      const Current_User = await get(GET_USER_PERSONAL_INFORMATION, requestUser);
      const NerWorth_Data = await get(GET_NET_WORTH_DATA, requestUser);
      const Income_Expence = await get(GET_REAL_ESTATE_HOLDINGS_WITH_IE, requestUser);


      const investmentIds = [
        ...(REAL_ESTATE_ASSETS.investments?.map(i => i.id) ?? []),
        ...(PRIVATE_EQUITY_INVESTMENTS.investments?.map(i => i.id) ?? []),
        ...(Royalty_Income.investments?.map(i => i.id) ?? []),
        ...(OPERATING_BUSINESS.data?.map(i => i.id) ?? []),
      ];
      const requestBody = {
        investmentIds: investmentIds,
      };

      const Cash_Values = await post(GET_CASH_VALUE_BY_IDS, JSON.stringify(requestBody));

      const UsedliquidAsset = new Set([
        ...(Cash_Values?.data?.flatMap(i =>
          i.cashAccountValues?.length > 0
            ? i.cashAccountValues.map(val => val.id)
            : []
        ) ?? [])
      ]);

      // console.log("=UsedliquidAsset=>>>",UsedliquidAsset);
      // console.log("=REAL_ESTATE_ASSETS=>>>",REAL_ESTATE_ASSETS);
      // console.log("=PRIVATE_EQUITY_INVESTMENTS=>>>",PRIVATE_EQUITY_INVESTMENTS);
      // console.log("=NerWorth_Data=>>>",NerWorth_Data);
      // console.log("=Income_Expence=>>>",Income_Expence);
      // console.log("=Cash_Values=>>>",Cash_Values);


      // console.log("==investmentIds>>>",investmentIds);



      let monthlyIncomeDetails = [];
      if (Income_Expence && Income_Expence.incomeExpenceDetails && Income_Expence.incomeExpenceDetails.length > 0) {

        monthlyIncomeDetails = Income_Expence.incomeExpenceDetails.map(item => {
          let totalIncome = 0;
          let totalExpense = 0;

          item.income_expense_details.forEach(detail => {
            let amount = parseFloat(detail.projected_amount);
            if (detail.type === "INCOME") {
              totalIncome += amount;
            } else if (detail.type === "EXPENSE") {
              totalExpense += amount;
            }
          });

          let monthlyIncome = (totalIncome - totalExpense) / 12;

          return {
            id: item.id,
            liquidAssetName: item.investment_name,
            monthlyIncome: monthlyIncome.toFixed(2)
          };
        });
      }


      // console.log("==monthlyIncomeDetails>>>",monthlyIncomeDetails);
      const incomeMap = new Map(monthlyIncomeDetails && monthlyIncomeDetails.length ? monthlyIncomeDetails.map(item => [item.id, item.monthlyIncome]) : []);
      const cashValuesMap = new Map(Cash_Values && Cash_Values.data && Cash_Values.data.length ? Cash_Values.data.map(item => [item.investmentId, item.totalCurrentValue]) : [])
      // console.log("==incomeMap>>>",incomeMap);
      // console.log("==cashValuesMap>>>",cashValuesMap);



      const categories = {
        liquidAssets: 0,
        realEstateHoldings: 0,
        privateEquity: 0,
        royaltyIncome: 0,
        personalAssets: 0
      };

      if (NerWorth_Data && NerWorth_Data.investmentData && NerWorth_Data.investmentData.length && NerWorth_Data.investmentData.length > 0) {
        NerWorth_Data.investmentData.forEach(item => {
          const category = item["investment_categories.value"];

          const amount = parseFloat(item.amount_borrowed) || 0;

          if (category === "Private Equity") categories.privateEquity += amount;
          if (category === "Real Estate Holdings") categories.realEstateHoldings += amount;
          if (category === "Liquid Assets") categories.liquidAssets += amount;
          if (category === "Personal Assets") categories.personalAssets += amount;
          if (category === "Royalty Income") categories.royaltyIncome += amount;
        });
      }

      let intrestEarned = 0;

      if (investmentsData && investmentsData.investments && investmentsData.investments.length > 0) {
        investmentsData.investments.forEach(item => {
          const amount = parseFloat(item.interest_earned) || 0;
          intrestEarned += amount;
        });
      }

      let businessTotal = 0
      let royaltyTotal = 0

      if (OPERATING_BUSINESS && OPERATING_BUSINESS.totals && OPERATING_BUSINESS.totals.total_inv_aft_Debt) {
        businessTotal = parseFloat(OPERATING_BUSINESS.totals.total_inv_aft_Debt);
      }
      if (Royalty_Income && Royalty_Income.totals && Royalty_Income.totals.total_inv_aft_Debt) {
        royaltyTotal = parseFloat(Royalty_Income.totals.total_inv_aft_Debt);
      }

      // console.log("=royaltyTotal=>>",royaltyTotal);


      let invValueAftDebt = {
        realEstateTotal: (REAL_ESTATE_ASSETS && REAL_ESTATE_ASSETS.totals && REAL_ESTATE_ASSETS.totals.total_inv_aft_Debt) ? parseFloat(REAL_ESTATE_ASSETS.totals.total_inv_aft_Debt) : 0,
        privateEquityTotal: (PRIVATE_EQUITY_INVESTMENTS && PRIVATE_EQUITY_INVESTMENTS.totals && PRIVATE_EQUITY_INVESTMENTS.totals.total_inv_aft_Debt) ? parseFloat(PRIVATE_EQUITY_INVESTMENTS.totals.total_inv_aft_Debt) : 0,
        businessRoyaltyTotal: businessTotal + royaltyTotal,
      }

      // console.log("=invValueAftDebt=>>",invValueAftDebt);
      const returnValidParsedFloatAmount = (amount) => {
        try {
          return validateAmount(amount) ? parseFloat(amount) : 0.00;
        } catch (error) {
          console.log('Error in the returnValidParsedFloatAmount :: ', error);
          return 0.00;
        }
      };
      const validateAmount = (amount) => {
        if (amount === null || amount === undefined || amount === '' || amount === 'null' || isNaN(Number(amount))) {
          return false;
        }

        const numericAmount = Number(amount);
        return numericAmount === 0 || numericAmount > 0;
      };
      const calculateShare = (currentValue, ownershipPercentage, amountBorrowed = 0) => {
        try {
          const parsedCurrentValue = returnValidParsedFloatAmount(currentValue);
          const parsedOwnershipPercentage = returnValidParsedFloatAmount(ownershipPercentage);
          const parsedAmountBorrowed = returnValidParsedFloatAmount(amountBorrowed);

          // If ownership percentage is not provided, return the current value minus borrowed amount (if any)
          const share = parsedCurrentValue - parsedAmountBorrowed;
          if (validateAmount(ownershipPercentage)) {
            return (share * parsedOwnershipPercentage) / 100;
          }
          // Subtract borrowed amount if provided
          return share;
        } catch (error) {
          console.log('ERROR in the calculateShare function ::', error)
          return 0.00;
        }

      }
     

      console.log("=investmentsData.investments==>>", investmentsData.investments);


      const liquidAssets = investmentsData.investments
        .filter(item => item.asset_type !== "Life Insurance" && !UsedliquidAsset.has(item.id))
        .map(item => ({
          'id': item.id || "",
          'custodian': item.holding_company || "",
          'productType': item.asset_type || "",
          'investmentName': item.investment_name || "",
          'currentValue': item.current_value || "0",
          'intrestEarned': item.interest_earned || "0",
          'cashFlow': item.projected_annual_income || "0",
        }));

      const cashFlow = liquidAssets.reduce((total, asset) => total + parseFloat(asset.cashFlow || 0), 0);

      const LIFEINSURANCE = investmentsData.investments
        .filter(item => item.asset_type == "Life Insurance" && !UsedliquidAsset.has(item.id))
        .map(item => ({
          'id': item.id || "",
          'custodian': item.holding_company || "",
          'investmentName': item.investment_name || "",
          'cashSurrenderValue': item.current_value || "0",
          'intrestEarned': item.interest_earned || "0"
        }));

      const REALESTATEASSETS = REAL_ESTATE_ASSETS.investments
        .map(item => ({
          'id': item.id || "",
          'holdingCompany': item.investment_name || "",
          'interest': getOwnershipPercentage(item.ownership_percentage),
          'cash': cashValuesMap.get(item.id) || "0",
          'currentValuation': item.current_value ? (parseFloat(item.current_value) * (parseFloat(getOwnershipPercentage(item.ownership_percentage)) / 100)).toFixed(2) : "0",
          'contingentLiability': item.contingent_liability || "0",
          'mortgageDebt': item.amount_borrowed || "0",
          'mortgageDebtPercentageValue': item.amount_borrowed ? (parseFloat(item.amount_borrowed) * (parseFloat(getOwnershipPercentage(item.ownership_percentage)) / 100)).toFixed(2) : "0",
          "monthlyIncome": incomeMap.get(item.id) || "0",
          'monthlyPayment': item.monthly_payment || "0",
          'maturity': item.maturity_date ? formatDate(item.maturity_date) : "",
          'invAftDebt': calculateShare(item.current_value,item.ownership_percentage,item.amount_borrowed) || "0"
        }));

      const realEstateMortgageDebt = REALESTATEASSETS.reduce((total, obj) => total + parseFloat(obj.mortgageDebtPercentageValue || 0), 0);

      const PRIVATEEQUITYINVESTMENTS = PRIVATE_EQUITY_INVESTMENTS.investments
        .map(item => ({
          'id': item.id || "",
          'holdingCompany': item.investment_name || "",
          'companyInvesting': item.company_investing || "",
          'interest': getOwnershipPercentage(item.ownership_percentage),
          'productType': item.property_type || "",
          'cash': cashValuesMap.get(item.id) || "0",
          'equityDebtInvested': item.equity_debt_invested || "0",
          'currentValuation': item.current_value ? (parseFloat(item.current_value) * (parseFloat(getOwnershipPercentage(item.ownership_percentage)) / 100)).toFixed(2) : "0",
          'distributionsNext12Months': item.projected_annual_income || "0",
          'monthlyPayment': item.monthly_payment || "0",
          'maturity': item.maturity_date ? formatDate(item.maturity_date) : "",
          'invAftDebt': calculateShare(item.current_value,item.ownership_percentage,item.amount_borrowed) || "0"
        }));

      // console.log("=PRIVATEEQUITYINVESTMENTS=>>>",PRIVATEEQUITYINVESTMENTS);


      const OPERATINGBUSINESSES = OPERATING_BUSINESS.data
        .map(item => ({
          'id': item.id || "",
          'holdingCompany': item.business_name || "",
          'interest': getOwnershipPercentage(item.ownership_percentage),
          'cash': cashValuesMap.get(item.id) || "0",
          'currentValuation': item.value ? (parseFloat(item.value) * (parseFloat(getOwnershipPercentage(item.ownership_percentage)) / 100)).toFixed(2) : "0",
          'contingentLiability': item.contingent_liability || "0",
          'entityLoanBalance': item.total_liabilities || "0",
          'annualPayment': item.annual_payments || "0",
          'monthlyPayment': item.monthly_payment || "0",
          'maturity': item.maturity_date ? formatDate(item.maturity_date) : "",
          'invAftDebt': calculateShare(item.total_assets, item.ownership_percentage, item.total_liabilities) || "0"
        }));

      const royaltyIncomes = Royalty_Income.investments.map(item => ({
        'id': item.id || "",
        'holdingCompany': item.investment_name || "",
        'interest': getOwnershipPercentage(item.percentage_interest),
        'cash': cashValuesMap.get(item.id) || "0",
        'currentValuation': item.current_value ? (parseFloat(item.current_value) * (parseFloat(getOwnershipPercentage(item.percentage_interest)) / 100)).toFixed(2) : "0",
        'contingentLiability': item.contingent_liability || "0",
        'entityLoanBalance': item.amount_borrowed || "0",
        'annualPayment': item.annual_payments || "0",
        'monthlyPayment': item.monthly_payment || "0",
        'maturity': item.maturity_date ? formatDate(item.maturity_date) : "",
        'invAftDebt': calculateShare(item.current_value,item.percentage_interest, item.amount_borrowed)|| "0"
      }));

      const OPERATING_BUSINESSES_ROYALTY_INTERESTS = [...OPERATINGBUSINESSES, ...royaltyIncomes];

      const PERSONALASSETS = PERSONAL_ASSETS.investments
        .map(item => ({
          'id': item.id || "",
          'description': item.investment_name || "",
          'currentValue': item.current_value || "0",
          'loanAmount': item.amount_borrowed || "0"
        }));

        const individualInformation = {
          'userName': Current_User.full_name || "",
          'userPhone': Current_User.phone || "",
          'userAddress': Current_User.address || "",
          'privateEquity': categories.privateEquity,
          'realEstateHoldings': categories.realEstateHoldings,
          'liquidAssets': categories.liquidAssets,
          'personalAssets': categories.personalAssets,
          'royaltyIncome': categories.royaltyIncome,
          'intrestEarned': intrestEarned,
          'realEstateTotal': invValueAftDebt.realEstateTotal,
          'privateEquityTotal': invValueAftDebt.privateEquityTotal,
          'businessRoyaltyTotal': invValueAftDebt.businessRoyaltyTotal,
          'cashFlow': cashFlow,
          'realEstateMortgageDebt':realEstateMortgageDebt
        };

      reportData = { "individualInformation": individualInformation, "liquidAssets": liquidAssets, "lifeInsurance": LIFEINSURANCE, 'realEsateAssests': REALESTATEASSETS, 'privateEquityInvestments': PRIVATEEQUITYINVESTMENTS, 'operatingBusinessRoyaltyIncome': OPERATING_BUSINESSES_ROYALTY_INTERESTS, 'personalProperty': PERSONALASSETS, 'notes': [] };

      console.log("reportData:", reportData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  }

  const generateReport = async () => {
    try {
      setLoading(true)
      await fetchReportData();
      const response = await fetch("/assets/template.xlsx");
      const arrayBuffer = await response.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);


      const mainWorkSheet = workbook.worksheets[0];
      const liquidAssetsWorkSheet = workbook.worksheets[1];
      const lifeInsuranceWorkSheet = workbook.worksheets[2];
      const realEstateWorkSheet = workbook.worksheets[3];
      const privateEquityWorkSheet = workbook.worksheets[4];
      const operatingBURoyaltyIncomeWorkSheet = workbook.worksheets[5];
      const personalAssetWorkSheet = workbook.worksheets[6];
      const notesWorkSheet = workbook.worksheets[7];

      setCellValues(mainWorkSheet, config.individualInformation, reportData.individualInformation);

      const tableOrder = [
        { key: "liquidAssets", workSheet: liquidAssetsWorkSheet, order: config.liquidAssets.order },
        { key: "lifeInsurance", workSheet: lifeInsuranceWorkSheet, order: config.lifeInsurance.order },
        { key: "realEsateAssests", workSheet: realEstateWorkSheet, order: config.realEsateAssests.order },
        { key: "privateEquityInvestments", workSheet: privateEquityWorkSheet, order: config.privateEquityInvestments.order },
        { key: "operatingBusinessRoyaltyIncome", workSheet: operatingBURoyaltyIncomeWorkSheet, order: config.operatingBusinessRoyaltyIncome.order },
        { key: "personalProperty", workSheet: personalAssetWorkSheet, order: config.personalProperty.order },
        { key: "notes", workSheet: notesWorkSheet, order: config.notes.order }
      ];

      tableOrder.sort((a, b) => a.order - b.order);

      let currentTableRow = config.tableStartRow;

      tableOrder.forEach(({ key, workSheet }) => {
        const configSection = config[key];
        const formattedDataSection = reportData[key] || [];

        if (configSection) {

          // Insert headers & column structure
          copyRows(2, configSection.headerCount + configSection.columnCount, currentTableRow, workSheet, mainWorkSheet, 1, [], [], [], '');

          // Insert data rows
          copyRows(
            2 + configSection.headerCount + configSection.columnCount,
            1,
            currentTableRow + configSection.headerCount + configSection.columnCount,
            workSheet,
            mainWorkSheet,
            formattedDataSection.length + configSection.emptyRows,
            configSection.valueColumns,
            formattedDataSection,
            [],
            'dataInsert'
          );

          // Insert footer
          copyRows(
            2 + configSection.headerCount + configSection.columnCount + 1,
            configSection.footerCount,
            currentTableRow + configSection.headerCount + configSection.columnCount + formattedDataSection.length + configSection.emptyRows,
            workSheet,
            mainWorkSheet,
            1,
            [],
            [],
            configSection.footerFormula,
            ''
          );
          startRow = null;
          endRow = null;

          // Update row position for the next table
          currentTableRow = tableEndRow + configSection.footerCount;
        }
      });

      mainWorkSheet.mergeCells(`A1:A${currentTableRow - 1}`);

      workbook.worksheets.slice(1, 8).forEach(sheet => workbook.removeWorksheet(sheet.id));


      const buffer = await workbook.xlsx.writeBuffer();

      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "PFS.xlsx");
    } catch (error) {
      console.error("Error processing the Excel file:", error);
    }
  }

  const getOwnershipPercentage = (ownershipPercentage) => {
    return (ownershipPercentage === 'null' || ownershipPercentage === 0 || !ownershipPercentage) ? 100 : ownershipPercentage;
  }


  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  const setCellValues = (worksheet, config, data) => {
    config.forEach((mapping) => {
      const [cell, property] = mapping.split(";");


      if (property && data && (data[property] || data[property] == 0) && data[property] !== undefined) {
        worksheet.getCell(cell).value = data[property];
      } else if (property.startsWith("formula=")) {
        let formula = property.replace("formula=", "");
        worksheet.getCell(cell).value = { formula: formula };
      }
    });
  }
  const fetchManagerList = async () => {
    try {
      setLoading(true);

      const managerList = await get(GET_MANAGER_LIST);
      const filteredMangerList = managerList.filter((item) => item.status == 1)
      const sortedManagerList = filteredMangerList.sort((a, b) => a.username.localeCompare(b.username));

      const choices = [
        { value: null, label: "All" },
        ...sortedManagerList.map((manager) => ({
          value: manager.id,
          label: manager.username,
        })),
      ];
      setSubscriberChoices(choices);

      setSelectedSubscriber(null);
    } catch (error) {
      console.error("Error fetching manager list:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <br />

      {/* Dropdown */}
      {checkPermission(userPermissions, "isAdmin") && (
        <div className="mb-4">
          <label
            htmlFor="subscriber-select"
            className="form-label d-block"
            style={{
              marginBottom: "5px",
              fontWeight: "500",
              fontSize: "14px",
              marginLeft: "14px",
            }} // Adjust label style
          >
            Select Subscriber
          </label>
          {subscriberChoices.length > 0 && (
            <Select
              id="subscriber-select"
              options={subscriberChoices}
              value={
                subscriberChoices.find(
                  (choice) => choice.value === selectedSubscriber
                ) || null
              }
              onChange={(option) =>
                setSelectedSubscriber(option ? option.value : null)
              }
              placeholder="Select a Subscriber"
              isClearable
              className="form-control-sm"
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "white",
                  height: "38px", // Standard Bootstrap input height
                  border: "2px solid #ebebeb",
                  fontSize: "14px",
                  borderRadius: "0.5rem",
                  fontWeight: "normal",
                }),
              }}
            />
          )}
        </div>
      )}
      {/* Generate Report Button */}
      <div className="d-flex flex-row justify-content-between w-100">
        <div>
        <button
          style={{ width: "100%", fontSize: "20px", marginLeft: "14px" }}
          type="button"
          onClick={generateReport}
          className="btn btn-lg btn-primary"
        >
          <span>
            <i className="zmdi zmdi-download"></i>
          </span>
          <span style={{ marginLeft: "10px" }}>Generate PFS Excel</span>
        </button>
        </div>
        <div style={{marginLeft: "5pt"}}>
        <Pdf_ReportGenerator selectedSubscriber={selectedSubscriber} />
        </div>
      </div>
    </>
  );
};

function copyRows(sourceStartRow, rowCount, targetStartRow, sourceSheet, targetSheet, pasteCount, valueColumns, data, footerFormula, type) {

  const numRows = rowCount;

  for (let p = 0; p < pasteCount; p++) {

    for (let i = 0; i < numRows; i++) {
      const sourceRow = sourceSheet.getRow(sourceStartRow + i);
      const targetRow = targetSheet.getRow(targetStartRow + p * numRows + i);

      targetRow.height = sourceRow.height;

      sourceRow.eachCell({ includeEmpty: true }, (sourceCell, colNumber) => {
        const targetCell = targetRow.getCell(colNumber);

        targetCell.style = { ...sourceCell.style };
        targetCell.value = sourceCell.value;

        // Handle merged cells
        if (sourceCell.isMerged) {
          const masterAddress = sourceCell.master.address;
          const masterRowNum = parseInt(masterAddress.match(/\d+/)[0], 10);
          const rowOffset = masterRowNum - sourceStartRow;
          const targetMasterRow = targetStartRow + p * numRows + rowOffset;
          const targetMasterAddress = masterAddress.replace(/\d+/, targetMasterRow);
          const targetRange = `${targetMasterAddress}:${targetCell.address}`;

          targetSheet.unMergeCells(targetRange);
          targetSheet.mergeCells(targetRange);
        }
      });
    }

    const dataRow = targetSheet.getRow(targetStartRow + p * numRows);

    if (type == 'dataInsert' && data && data.length == 0) {
      if (startRow == null) {
        startRow = dataRow.number;
      }
      endRow = dataRow.number;
    }


    if (data && data.length > 0 && valueColumns && valueColumns.length > 0) {
      const record = data[p] || {};

      if (startRow == null) {
        startRow = dataRow.number;
      }
      endRow = dataRow.number;

      valueColumns.forEach((mapping) => {
        const parts = mapping.split(";");
        const colLetter = parts[0];
        const property = parts[1] || null;
        const type = parts[2] || null;
        const colIndex = getColumnIndex(colLetter);

        const targetCell = dataRow.getCell(colIndex);
        if (colIndex && record[property] !== undefined) {
          if (type === "currency") {
            const numericValue = record[property] ? parseFloat(record[property]) : '';
            targetCell.value = numericValue;
            if (numericValue || numericValue === 0) {
              targetCell.numFmt = '"$"#,##0.00';
            }
          } else if (type === "percentage") {
            const numericValue = record[property] ? parseFloat(record[property]) / 100 : '';
            targetCell.value = numericValue;
            if (numericValue || numericValue === 0) {
              targetCell.numFmt = '0.00%';
            }
          } else {
            targetCell.value = record[property];
          }
        } else if (type && type.startsWith("formula=")) {
          let formula = type.replace("formula=", "").trim();

          formula = formula.replace(/<<row>>/g, dataRow.number);

          targetCell.value = { formula: formula };
          targetCell.numFmt = '"$"#,##0.00';
        }

      });
    }

    if (footerFormula && footerFormula.length > 0) {

      footerFormula.forEach((mapping) => {
        const parts = mapping.split(";");
        const colLetter = parts[0];
        const formulaText = parts[1] || null;
        const refrencCell = parts[2] || null;
        const colIndex = getColumnIndex(colLetter);

        const targetCell = dataRow.getCell(colIndex);
        if (colIndex !== undefined && formulaText.startsWith("formula=")) {
          let formula = formulaText.replace("formula=", "").trim();

          formula = formula.replace(/<<startRow>>/g, startRow).replace(/<<endRow>>/g, endRow);

          targetCell.value = { formula: formula };
          targetCell.numFmt = '"$"#,##0.00';

          if (refrencCell && refrencCell.startsWith("reference=")) {
            let cellValue = refrencCell.replace("reference=", "").trim();

            if (cellValue) {
              let cells = cellValue.split(',');


              cells.forEach(cell => {
                if (cell) {
                  let [cellRef, flag] = cell.trim().split('//'); 
                  
                  const targetRefCell = targetSheet.getCell(cellRef);
                  targetRefCell.value = { formula: flag === "multiply" ? `${targetCell._address} * 12` : targetCell._address };
                  targetRefCell.numFmt = '"$"#,##0.00';
                }
              });
            }
          }
        }

      });
    }
    tableEndRow = dataRow.number;

  }
}

function getColumnIndex(letter) {
  return letter.charCodeAt(0) - 64;
}

export default Excel_ReportGenerator;