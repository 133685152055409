import React, { useState, useEffect, useContext } from 'react';
import Excel_ReportGenerator from './Excel_ReportGenerator';
import Pdf_ReportGenerator from './Pdf_ReportGenerator';

const ReportCreation = () => {

  return (
    <>
      <div className="main-content introduction-farm">
        <div className="content-wraper-area">
          <div className="dashboard-area">
            <div className="container-fluid">
              <div className="card d-flex  ">
                <div
                  className="card-body d-flex flex-row w-50"
                  style={{ height: "100%" }}
                > 
                  <div className="w-40">
                    <Excel_ReportGenerator />
                  </div>
                  {/* <div className="w-60">
                    <Pdf_ReportGenerator />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportCreation;