import React, { useContext, useEffect, useState } from "react";
import {
  CREATE_STRIPE_CHECKOUT,
  GET_ACTIVE_STRIPE_PRODUCT,
} from "../api/endpoints";
import { get, post } from "../api";
import { PageLoader } from "../Context/PageLoader";

const Stripe = ({ setCheckout,userId, userName, userEmail, handlePaymentBack,full_name,phone, showBackBtn, isNewPage }) => {
  const [paymentPlan, setPaymentPlan] = useState([]);
  const { loading, setLoading } = useContext(PageLoader);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isEmptyPaymentCategory, setIsEmptyPaymentCategory] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");

  const fetchProductInfo = async () => {
    try {
      setLoading(true);
      const response = await get(GET_ACTIVE_STRIPE_PRODUCT);
      if (response) {
        // console.log("Stripe Response Data: ", response);
        setPaymentPlan(response);
      }
    } catch (error) {
      console.error("Error while fetching Stripe Product information:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("User Name: ", userName);
    fetchProductInfo();
  }, [setLoading]);

  const handleCheckout = async (priceId, paymentType) => {
    if (priceId && userEmail && paymentType) {
      try {
        const data = await post(CREATE_STRIPE_CHECKOUT, {
          userId,
          priceId,
          email: userEmail,
          userName:userName,
          paymentType,
          full_name,
          address: "test",
          phone
        });
        // console.log("Checkout Data: ", data);

        if (data?.url) {
          if (isNewPage) {
            window.open(data.url, "_blank");
        } else {
            window.location.href = data.url;
        }
        } else {
          console.error("No valid checkout URL received.");
        }
      } catch (error) {
        console.error("Checkout error:", error);
      }
    } else {
      setIsEmptyPaymentCategory(true);
      if (!userEmail) {
        setPaymentErrorMessage("Please enter valid user email");
      } else {
        setPaymentErrorMessage("Please select a payment option.");
      }
    }
  };

  const handleNavigateBack = (event) => {
    handlePaymentBack();
  };

  return (
    <div className="card container">
      <div className="d-flex flex-column align-items-center">
        {paymentPlan.map((product) => (
          <div
            key={product.id}
            className={`card w-100 my-2 p-3 ${
              selectedPlan === product.name
                ? "bg-primary text-white"
                : "border-light"
            }`}
            onClick={() => setSelectedPlan(product.name)}
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              background: "#fff",
              border: "1px solid #f3f3f3",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="plan"
                  checked={selectedPlan === product.name}
                  onChange={() => setSelectedPlan(product.name)}
                />
                <label className="form-check-label ms-2">{product.name}</label>
              </div>
              <h4>
                ${(product.price / 100).toFixed(2)}{" "}
                {product.currency.toUpperCase()}
              </h4>
            </div>
          </div>
        ))}
        <div className="mb-3 w-100 d-flex gap-3 mt-3">
          {showBackBtn && (
            <button
              className="btn btn-outline-secondary btn-lg w-50"
              type="button"
              style={{
                backgroundColor: "transparent",
                border: "1px solid #ccc",
                color: "#000",
                transition: "none",
              }}
              onClick={handleNavigateBack}
            >
              Back
            </button>
          )}

          <button
            className={`btn btn-primary btn-lg ${
              showBackBtn ? "w-50" : "w-100"
            }`}
            type="button"
            onClick={() => {
              const selectedProduct = paymentPlan.find(
                (p) => p.name === selectedPlan
              );
              if (selectedProduct) {
                handleCheckout(selectedProduct.priceId, selectedProduct.type);
              } else {
                handleCheckout("", "");
              }
            }}
          >
            Subscribe
          </button>
        </div>
        {isEmptyPaymentCategory && (
          <span style={{ fontSize: "12pt", color: "red" }}>
            {paymentErrorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default Stripe;
