import React, { useEffect, useState, useRef } from 'react';
import Chart from 'react-apexcharts'
import { useLocation } from 'react-router-dom';

const PieChartV2 = ({ header, data, labelKey, seriesKey, onChange,  handleDoubleClick, filteredChartValue = '', removeChartIndex = false }) => {
    const [tempSelectedChartValue, setTempSelectedChartValue] = useState("");
    const [clickEvent, setClickEvent] = useState("");
    const [chartIndex, setChartIndex] = useState(null)
    const location = useLocation();
    const headerName = header || 'chart';
    const chartRef = useRef(null);

    const [pieChart, setPieChart] = useState({
        series: [],
        options: {
            labels: [],
            chart: {
                type: 'pie',
                fontFamily: 'inter, sans-serif',
            },
            colors: [
                "#3e9efc", // Blue
                "#4ee5a6", // Green
                "#ff9f00", // Orange
                "#8e44ad", // Purple
                "#3498db", // Sky Blue
                "#f1c40f", // Yellow
                "#2ecc71", // Emerald
                "#7698b3", // Dark
                "#e67e22", // Carrot
                "#95a5a6", // Concrete
                "#16a085", // Green Sea
                "#9b59b6", // Amethyst
                "#f39c12"  // Orange
            ],
           
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Inter, sans-serif',
                    colors: ['#fff']
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(value);
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 1900,
                    options: {
                        chart: {
                            width: 500,
                            height: 300,
                            events: {
                                dataPointSelection: (event, chartContext, config) => {
                                    console.log('config.dataPointIndex :: ', config.dataPointIndex)
                                    if(config.dataPointIndex == chartIndex){
                                        setChartIndex(null)
                                    }else{
                                        setChartIndex(config.dataPointIndex)
                                    }
                                    let selectedData = config.w.config.labels[config.dataPointIndex];
                                    selectedData = selectedData.replace(/\s*\(.*?\)\s*/, '');
                                    setTempSelectedChartValue(selectedData, data);
                                    if (event?.detail == 2) {
                                        setClickEvent(selectedData);
                                    }
                                },
                            },
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: "bottom",
                            horizontalAlign: 'center',
                            floating: false,
                            offsetY: 0,
                            fontSize: '12px', 
                            onItemClick: {
                                toggleDataSeries: true,
                              },
                              selectedSeriesIndex: 1 
                        },
                    },
                },
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: 200,
                            height: 400
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
                {
                    breakpoint: 400,
                    options: {
                        chart: {
                            width: 330,
                            height: 250
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "20px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    useEffect(() => {
        const transformedData = data.reduce((result, item) => {
            let assetType = labelKey == "operating_bussiness"? 'Operating Business' : item[labelKey];
            const currentValue = item[seriesKey[0]] ? parseFloat(item[seriesKey[0]]) : 0.00;
            const investedValue = item[seriesKey[1]] ? parseFloat(item[seriesKey[1]]): 0.00;
         
            if (assetType in result) {
                result[assetType].currentValue += currentValue;
                result[assetType].investedValue += investedValue;
            } else {
                result[assetType] = { currentValue };
                result[assetType].investedValue = investedValue;
            }
            if(assetType == 'Operating Business' ){
            }
            return result;
        }, {});

        const totalSum = Object.values(transformedData).reduce((acc, asset) => acc + asset.currentValue, 0);

        // Calculate percentages and create labeled data
        const labeledData = {};
        Object.keys(transformedData).forEach((key) => {
                const percentage = ((transformedData[key].currentValue / totalSum) * 100).toFixed(1);
                labeledData[`${key} (${percentage}%)`] = transformedData[key].currentValue;
        });

        let tempLabels = Object.keys(labeledData);

        setPieChart((prevStockOverView) => ({
            ...prevStockOverView,
            series: Object.values(labeledData),
            options: {
                ...prevStockOverView.options,
                labels: Object.keys(labeledData)
            },
        }));

        if(filteredChartValue){
            const selectChartIndex = tempLabels.findIndex(item => item.includes(filteredChartValue));
            if (data.length) {
                setTimeout(() => {
                  if(chartRef.current?.chart){
                      chartRef.current.chart.toggleDataPointSelection(selectChartIndex);
                  }
              }, 100);
            }
        }
     
    }, [data, filteredChartValue]);

    useEffect(() => {
        if(removeChartIndex && (chartIndex || chartIndex == 0)){
            setTimeout(() => {
                if(chartRef.current?.chart){
                    chartRef.current.chart.toggleDataPointSelection(chartIndex);
                }
            }, 100);
        }
    }, [removeChartIndex])


    useEffect(() => {
        if (tempSelectedChartValue) {
            if (location.pathname != '/portfolio-summary') {
                onChange(tempSelectedChartValue)
            }
            if (clickEvent !== "" && location.pathname === '/portfolio-summary') {
                handleDoubleClick(clickEvent)
            }
            setTempSelectedChartValue("")
        }
    }, [tempSelectedChartValue]);

    return (
        <div className="row ">
            <div className="col-12 mb-20 mb-2">
                <div className="card">
                    <div className="card-body">
                        <div className=" single-widget d-flex align-items-center justify-content-between" style={{ marginBottom: '5px' }}>
                            <div className="card-title">
                                <h4>{`${headerName}`}</h4>
                            </div>
                        </div>
                        <div id="stock-overview" style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {data?.length ?
                                <Chart
                                    ref={chartRef}
                                    options={pieChart.options}
                                    series={pieChart.series}
                                    type={'pie'} height={300} width={500} /> :
                                <div className="datachart-nodata">No data available</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PieChartV2; 