import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Mock products
const products = [
  { id: 1, name: 'Product A', price: 100 },
  { id: 2, name: 'Product B', price: 200 },
  { id: 3, name: 'Product C', price: 300 },
];

const ProductList = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateTotal = (values) => {
    const total = selectedProducts.reduce((acc, product) => {
      const quantity = parseInt(values[`quantity_${product.id}`]) || 0;
      return acc + product.price * quantity;
    }, 0);
    setTotalPrice(total);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Product List</h2>
      <Formik
        initialValues={{
          email: '',
          address: '',
          ...products.reduce((acc, product) => ({ ...acc, [`quantity_${product.id}`]: '' }), {}),
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Required'),
          address: Yup.string().required('Required'),
          ...products.reduce((acc, product) => ({
            ...acc,
            [`quantity_${product.id}`]: Yup.number()
              .min(0, 'Quantity cannot be negative')
              .integer('Quantity must be an integer'),
          }), {}),
        })}
        onSubmit={(values) => {
          const orderDetails = selectedProducts.map((product) => ({
            productId: product.id,
            productName: product.name,
            quantity: values[`quantity_${product.id}`],
          }));
          console.log('Order Submitted:', {
            email: values.email,
            address: values.address,
            total: totalPrice,
            orderDetails,
          });
          // Call Stripe API or your backend to process payment here
        }}
      >
        {({ values }) => (
          <Form>
            <div className="row">
              {products.map((product) => (
                <div className="col-md-4 mb-3" key={product.id}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{product.name}</h5>
                      <p className="card-text">Price: ${product.price}</p>
                      <label htmlFor={`quantity_${product.id}`} className="form-label">
                        Quantity
                      </label>
                      <Field
                        type="number"
                        className="form-control"
                        id={`quantity_${product.id}`}
                        name={`quantity_${product.id}`}
                        min="0"
                        onChange={(e) => {
                          const value = e.target.value;
                          values[`quantity_${product.id}`] = value;
                          if (value > 0) {
                            setSelectedProducts((prev) =>
                              prev.some((p) => p.id === product.id)
                                ? prev
                                : [...prev, product]
                            );
                          } else {
                            setSelectedProducts((prev) =>
                              prev.filter((p) => p.id !== product.id)
                            );
                          }
                          calculateTotal(values);
                        }}
                      />
                      <ErrorMessage
                        name={`quantity_${product.id}`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <h4>Total Price: ${totalPrice}</h4>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Field type="email" className="form-control" id="email" name="email" />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </div>

            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <Field as="textarea" className="form-control" id="address" name="address" />
              <ErrorMessage name="address" component="div" className="text-danger" />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit Payment
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductList;
