import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer";
import { PageLoader } from '../../Context/PageLoader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get, put } from '../../api';
import { GET_USER_PERSONAL_INFORMATION, UPDATE_USER_PERSONAL_INFORMATION, CHANGE_USER_PASSWORD } from '../../api/endpoints';
import { concatUrlWithId } from '../../utils/commonUtils';
import { FormField } from '../../components/Forms/FormFields';
import ProfileUpload from '../../components/DocumentManager/ProfileUpload';
import { UserPermissionStore } from '../../Store/UserPermission';

const personalInformationSchema = Yup.object().shape({
    first_name: Yup.string().label('First Name').trim().required('First Name is required'),
    last_name: Yup.string().label('Last Name').trim().required('Last Name is required'),
    user_name: Yup.string().label('User Name').trim().required('User Name is required'),
    email: Yup.string().label('Email').trim().matches(/^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/, 'Invalid email address').required('Email is required'),
    mobile_no: Yup.string().label('Mobile No').trim().required('Mobile Number is required')
});

const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().label('Current Password').trim().required('Current Password is required'),
    new_password: Yup.string().label('New Password').trim().required('New Password is required'),
    confirm_password: Yup.string().label('Confirm Password').trim()
        .oneOf([Yup.ref('new_password'), null], "Passwords don't match")
        .required('Confirm Password is required')
});

function UserInfo({ userDetails }) {
    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        user_name:'',
        email:'',
        mobile_no:'',
        user_image:'',
        address:'',
    });
    const [initialChangePasswordValues, setInitialChangePasswordValues] = useState({
        old_password: '',
        new_password: '',
        confirm_password:''
    });
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false); 
    const [isProfileUpload, setProfileUpload] = useState(false); 
    const { loading, setLoading } = useContext(PageLoader);
    const [previousUrl, setPreviousUrl] = useState('');
    const [investmentId, setInvestmentId] = useState('');
    const { clearPermissions } = UserPermissionStore();
    const history = useNavigate();
    const fetchUserInfo = async () => {
        try {
            const response = await get(GET_USER_PERSONAL_INFORMATION);;
            if (response) {
              const fullNameParts = response.full_name.split(" - ");
              const firstName = fullNameParts[0];
              const lastName = fullNameParts[1];
              let values = {
                first_name: firstName,
                last_name: lastName,
                user_name: response.username,
                email: response.email,
                mobile_no: response.phone,
                user_image: response.profileImagePath,
                address:response.address
              };
              setInitialValues(values);
              const profileImage = document.getElementById("userProfile");
              profileImage.src = response.profileImagePath
                ? response.profileImagePath
                : "img/profile-image.png";
              localStorage.removeItem("profileImageUrl");
              localStorage.setItem(
                "profileImageUrl",
                JSON.stringify(response.profileImagePath)
              );
            }
        } catch (error) {
          console.log("Error while fetching user information:", error);
        }
    };
    useEffect(() => {
       fetchUserInfo();
    }, []);

    const handleUpdatePersonalInfo = async (values) => {
       setLoading(true)
        try {
            const url = concatUrlWithId(UPDATE_USER_PERSONAL_INFORMATION,investmentId);
            const bodyPayload = {
                investment_name: values.investment_name,
                full_name: `${values.first_name} - ${values.last_name}`,
                user_name: values.user_name,
                email: values.email,
                mobile_no: values.mobile_no.replace(/\D/g, ''),
                address: values.address
            };
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`${values.first_name} ${values.last_name} Personal Information Updated Successfully...`, 'success');
                fetchUserInfo();
            } else {
                notify('`Person Information update failed...', 'error');
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notify(error?.response?.data?.error, 'error');
            console.error('API Error:', error);
        }
    };

    const handleUpdatePassword = async (values) => {
        // Logic to update password
        try {
            const url = concatUrlWithId(CHANGE_USER_PASSWORD,investmentId);
            const bodyPayload = {
                old_password: values.old_password,
                new_password: values.new_password,
                confirm_password: values.confirm_password
            };
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Password updated Successfully...`, 'success');
                localStorage.removeItem('token'); 
                localStorage.removeItem('authToken'); 
                clearPermissions();
                localStorage.removeItem('user-permission');
                window.location.href = '/login'; 
            } else {
                notify('Real Estate update failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.error, 'error');
            console.error('API Error:', error);
        }
    };

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return ''; 
        const formattedValue = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedValue;
    }

    function imageUpload() {
        setProfileUpload(true);
    }
    const handleClosePopup = (value) =>{
        setProfileUpload(value);
        fetchUserInfo();
    }

    const notify = (message, type) => toast(message, { type });
    return (
        <>

            <div className="main-content introduction-farm">
                    <div className="content-wraper-area">
                        <div className="dashboard-area">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* Personal Information Formik */}
                                    <div className="col-lg-6">
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize
                                            validationSchema={personalInformationSchema}
                                            onSubmit={(values, actions) => {
                                                handleUpdatePersonalInfo(values);
                                                setEditMode(!editMode);
                                            }}
                                        >
                                            {props => (
                                                <Form>

                                                    <fieldset>
                                                        <legend>Personal Info</legend>
                                                        <div className="d-sm-flex align-items-top profile-user-header main-profile-cover profile-card">
                                                            <div className="single-profile-image" style={{cursor: "pointer"}} onClick={imageUpload}>
                                                                <img src={initialValues.user_image || "/img/profile-image.png"} alt="" style={{height: "70px", width: "70px", maxWidth: "70px", important: "true" }}  />
                                                            </div> 
                                                            {isProfileUpload && <ProfileUpload showUploadPopup={isProfileUpload} handleClosePopup={handleClosePopup} />}
                                                            <div className="flex-fill main-profile-meta" >
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <h6>{initialValues.first_name}  {initialValues.last_name}</h6>
                                                                        {/* <p className="mb-2">Chief Developer</p> */}
                                                                    </div>
                                                                    {!editMode && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>{editMode ? 'View Profile Info' : 'Edit Profile Info'}</Tooltip>} >
                                                                                            <i className={`bx ${editMode ? 'bxs-show' : 'bxs-edit'} btn btn-circle`} onClick={() => setEditMode(!editMode)} style={{ fontSize: '19px', margin: '2px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                                                            </OverlayTrigger> }
                                                                </div>
                                                            </div>
                                                    </div>
                                                        <FormField viewMode={!editMode} fieldName="first_name" label="First Name" value={initialValues.first_name} maxLength={200} />
                                                        <FormField viewMode={!editMode} fieldName="last_name" label="Last Name" value={initialValues.last_name} maxLength={200} />
                                                        <FormField viewMode={!editMode} fieldName="user_name" label="User Name" value={initialValues.user_name} maxLength={200} />
                                                        <FormField viewMode={!editMode} fieldName="email" label="Email" value={initialValues.email} maxLength={200} />
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="formrow-holding-company-input">Mobile No<span className='man-start'>{editMode? "*" : ''}</span></label>
                                                               {editMode && (
                                                                  <Field className="form-control" maxLength={14}
                                                                    type="tel" id="formrow-holding-company-input"  name="mobile_no" 
                                                                     onInput={(e) => {
                                                                    const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10); 
                                                                    const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                                    e.target.value = formattedValue; }}
                                                                />
                                                            )}
                                                            {!editMode && <div className='view-label'>{formatPhoneNumber(initialValues.mobile_no)}</div>}
                                                            <ErrorMessage name="mobile_no" component="div" className="error-message" />
                                                        </div>
                                                        <FormField viewMode={!editMode} fieldName="address" label="Address" value={initialValues.address} maxLength={200} />

                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                            {editMode && <button type="reset" onClick={() => setEditMode(!editMode)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}
                                                            {editMode && <button type="submit" onClick={() => setIsSaveNew(false)} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>

                                    {/* Change Password Formik */}
                                    <div className="col-lg-6">
                                        <Formik
                                            initialValues={{
                                                old_password: '',
                                                new_password: '',
                                                confirm_password: ''
                                            }}
                                            validationSchema={changePasswordSchema}
                                            onSubmit={(values, actions) => {
                                                handleUpdatePassword(values)
                                                actions.resetForm()
                                            }}
                                        >
                                            {props => (
                                                <Form>
                                                    {/* <p>Change Password</p> */}
                                                    <fieldset>
                                                    <legend>Change Password</legend>
                                                        <FormField viewMode={viewMode} fieldName="old_password" label="Current Password" value={initialChangePasswordValues.old_password} type='password' maxLength={200} />
                                                        <FormField viewMode={viewMode} fieldName="new_password" label="New Password" value={initialChangePasswordValues.new_password} type='password' maxLength={200} />
                                                        <FormField viewMode={viewMode} fieldName="confirm_password" label="Confirm Password" value={initialChangePasswordValues.confirm_password} type='password' maxLength={200} />
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                            {!viewMode && <button type="submit" onClick={() => setIsSaveNew(false)} className="btn btn-primary w-md">Update</button>}
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
        </>
    );
}
export default UserInfo;