import { useState, useContext, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { PageLoader } from "../../Context/PageLoader";
import "react-confirm-alert/src/react-confirm-alert.css";
import Datatable from "../../components/Table/Datatable";
import { Button, Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Stripe from "../Stripe"; // Assuming this is your existing Stripe component for new payments
import { get, post } from "../../api";
import {
  GET_USER_SUBSCRIPTION_LIST,
  UPDATE_PAYMENT_METHODE,
} from "../../api/endpoints";

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SubscriptionDetails() {
  const { setLoading } = useContext(PageLoader);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [userActiveSubscriptionDetails, setUserActiveSubscriptionDetails] = useState([]);
  const [showUpdatePayment, setShowUpdatePayment] = useState(true);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    email: "",
    mobile_no: "",
    user_image: "",
  });

  const columns = [
    { dataField: "id", text: "ID", csvExport: false, hidden: true },
    {
      dataField: "subscription_plan",
      text: "Subscription Plan",
      headerClasses: "text-center",
    },
    { dataField: "amount", text: "Amount", headerClasses: "text-center" },
    {
      dataField: "start_date",
      text: "Start Date",
      isDateAndTimeField: true,
      headerClasses: "text-center",
    },
    {
      dataField: "end_date",
      text: "End Date",
      isDateField: false,
      headerClasses: "text-center",
    },
    {
      dataField: "tempStatus",
      text: "Status",
      sort: true,
      headerClasses: "text-center",
    },
  ];

  const formatSubscriptionData = (data) => {
    return (
      data?.map((item) => {
        // Handle One-Time Payments
        let subscriptionPlan = "N/A";
        if (Array.isArray(item.subscription_plan)) {
          subscriptionPlan = item.subscription_plan[0]?.product_name || "N/A";
        } else if (typeof item.subscription_plan === "string") {
          subscriptionPlan = item.subscription_plan;
        }
  
        return {
          id: item.id,
          subscription_plan: subscriptionPlan,
          amount: `$${(item.amount).toFixed(2)}`,
          start_date: new Date(item.start_date).toLocaleDateString(),
          end_date: item.end_date && item.end_date != null ? new Date(item.end_date).toLocaleDateString() : "--",
          status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
          showPaymentUpdateBtn: item.status.toLowerCase() === "succeeded" ? false : true,
          tempStatus: item.status.toLowerCase() === "succeeded" ? "paid" : item.status,

        };
      }) || []
    );
  };

  const fetchUserSubscriptionDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get(GET_USER_SUBSCRIPTION_LIST);
      if (!response){
        setLoading(false);
        throw new Error("Invalid response from server");
      } 

      const userData = response?.userData || {};
      const fullName = userData.full_name?.trim();

      setUserDetails({
        id: userData?.id,
        fullName: fullName || "test",
        customerId: userData?.customer_id,
        user_name: userData.username || "",
        email: userData.email || "",
        mobile_no: userData.mobile_no || "",
        user_image: userData.user_image || "",
      });

      const formattedData = formatSubscriptionData(response?.paymentData);
      setUserActiveSubscriptionDetails(
        formattedData.filter((sub) => sub.status === "Active" || sub.status === 'Succeeded')
      );
      setUserSubscriptionList(
        formattedData.filter((sub) => sub.status !== "Active" && sub.status !== 'Canceled')
      );
    } catch (error) {
      console.error("Error fetching user subscriptions:", error);
      setLoading(false);
      toast.error("Failed to load subscription details.");
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    fetchUserSubscriptionDetails();
  }, [fetchUserSubscriptionDetails]);

  const handleUpdatePaymentMethod = async (paymentMethodId) => {
    try {
      setLoading(true);
      const response = await post(UPDATE_PAYMENT_METHODE, {
        paymentMethodId,
        customerId: userDetails.customerId,
      });

      if (response.success) {
        toast.success(
          response.message || "Payment method updated successfully!"
        );
        setShowModal(false);
      } else {
        toast.error(response.error || "Payment method update failed!");
      }
    } catch (error) {
      console.log("Catch Error===>", error);
      let errorMsg;
      if (error.response) {
        errorMsg = error.response.data.error || error.response.data.message;
      } else {
        errorMsg = "Payment service is currently unavailable. Try again later.";
      }
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  return (
    <>
      <div className="container py-4 center">
        <ActivePlanSection
          activeSubscription={userActiveSubscriptionDetails?.[0]}
          onUpdatePayment={() => openModal("update")}
          onNewPayment={() => openModal("new")}
        />

        {userSubscriptionList && userSubscriptionList.length > 0 ? (
          <>
            <hr className="my-4" />
            <PaymentHistorySection />
            <Datatable
              data={userSubscriptionList}
              columns={columns}
              disableSearch
            />
          </>
        ) : (
          <div></div>
        )}
      </div>

      {/* Modal for Update Payment Method or New Payment */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "update"
              ? "Update Payment Method"
              : "Make New Payment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          {modalType === "update" ? (
            <Elements stripe={stripePromise}>
              <UpdatePaymentMethodForm
                userDetails={userDetails}
                onUpdatePaymentMethod={handleUpdatePaymentMethod}
                onHide={() => setShowModal(false)}
              />
            </Elements>
          ) : (
            <Stripe
              setCheckout={true}
              userId={userDetails?.id}
              userName={userDetails?.user_name}
              userEmail={userDetails?.email}
              address={userDetails?.address}
              phone={userDetails?.phone}
              full_name={userDetails?.fullName}
              showBackBtn={false}
              isNewPage={false}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function UpdatePaymentMethodForm({
  userDetails,
  onUpdatePaymentMethod,
  onHide,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    // Call the parent function to update the payment method
    await onUpdatePaymentMethod(paymentMethod.id);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div className="d-flex justify-content-end mt-3">
        <Button variant="secondary" onClick={onHide} className="me-2">
          Cancel
        </Button>
        <Button type="submit" disabled={!stripe || loading}>
          {loading ? "Updating..." : "Update Payment Method"}
        </Button>
      </div>
    </form>
  );
}

function ActivePlanSection({
  activeSubscription,
  onUpdatePayment,
  onNewPayment,
}) {
  return (
    <div
      className="card shadow-sm p-4 rounded-4 border-0"
      style={{ background: "#F4F6FC" }}
    >
      {activeSubscription ? (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="text-primary fw-semibold mb-2">Active Plan</h6>
            <h2 className="fw-bold text-success">
              {activeSubscription?.subscription_plan || "No Active Plan"}
            </h2>
            <ul>
              <li style={{ listStyle: "inherit" }}>
                <p className="text-muted mb-1">
                  <span className="fw-semibold">Amount:</span>{" "}
                  <strong>{activeSubscription?.amount || "-"}</strong>
                </p>
              </li>
              {/* <li style={{ listStyle: "inherit" }}>
                <p className="text-muted mb-1">
                  <span className="fw-semibold">Start Date:</span>{" "}
                  <strong>{activeSubscription?.start_date || "-"}</strong>
                </p>
              </li> */}
              <li style={{ listStyle: "inherit" }}>
                <p className="text-muted mb-1">
                  <span className="fw-semibold">End Date:</span>{" "}
                  <strong>{activeSubscription?.end_date || "-"}</strong>
                </p>
              </li>
            </ul>
          </div>
          <div>
          {activeSubscription?.showPaymentUpdateBtn === true && (
            <Button className="fw-bold me-2" onClick={onUpdatePayment}>
              Update Payment Method
            </Button>
          )}
            
          </div>
        </div>
      ) : (
        <>
          <div className="w-50 text-center mx-auto p-4">
            <h1 className="fw-bold text-danger">No Active Plan</h1>
            <h6 className="fw-semibold text-muted">
              You don’t have a necessary subscription plan. Please update your
              payment accordingly!
            </h6>
            <button
              className="btn btn-primary fw-bold mt-3"
              onClick={onNewPayment}
            >
              New Payment
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function PaymentHistorySection() {
  return (
    <div className="row">
      <div className="col-md-6">
        <h5 className="fw-bold">Payment History</h5>
        <p className="text-secondary">See history of your subscriptions</p>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
