import React, { useState, useContext, useEffect } from "react";
import { PageLoader } from "../Context/PageLoader";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "./WMS.png";
import * as Yup from "yup";
import { UserPermissionStore } from "../Store/UserPermission";
import { TextAreaField } from "../components/Forms/FormFields";
import Stripe from "./Stripe";
import axios from "axios"; // Import axios for API calls
import { post } from "../api";
import { USER_NAME_VALIDATION } from "../api/endpoints";

export const investmentSchema = Yup.object().shape({
  firstName: Yup.string().label('First Name').trim().required('First Name  is required'),
  lastName: Yup.string().label('Last Name').trim().required('Last Name is required'),
  username: Yup.string().label('User Name').trim().required('User Name is required').test('no-spaces', 'Username cannot contain spaces', value => !/\S\s+\S/.test(value)),
  email: Yup.string().label('Email').email('Invalid email address').matches(/^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/, 'Invalid email address').required('Email is required'),
  phone: Yup.string().label('Mobile Number').trim().required('Mobile Number is required').matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid mobile number'),
});

function SignUp() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [visiblePayment, setvisiblePayment] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const { setLoading } = useContext(PageLoader);
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    full_name: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleCheckout = async (values) => {
    setIsSubmitButtonDisabled(true);
    try {
      setErrorMessage(""); // Clear any previous errors

      // Call your API
      const response = await post(USER_NAME_VALIDATION, values);
      // If the API call is successful, proceed to payment
      setUserDetails({
        setCheckout: true,
        userName: values.username,
        userEmail: values.email,
        full_name: `${values.firstName} - ${values.lastName}`,
        phone: values.phone,
      });
      setInitialValues(values);
      setvisiblePayment(true);
    } catch (error) {
      // Handle API errors
      if (error.response) {
        setErrorMessage(error.response.data.message || "An error occurred during sign up.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setIsSubmitButtonDisabled(false);
    }
  };

  const handlePaymentBack = () => {
    setvisiblePayment(false);
  };

  const handleClickLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="main-content h-100vh">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-sm-10 col-md-7 col-lg-5">
              <div className="middle-box">
                <div className="card-body">
                  <div className="log-header-area card p-4 mb-4 text-center">
                    <img src={logo} className="App-logo" alt="logo" />
                  </div>
                  {!visiblePayment && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={investmentSchema}
                      onSubmit={(values) => handleCheckout(values)}
                    >
                      {({ errors, touched, submitForm }) => (
                        <Form>
                          <div className="card">
                            <div className="card-body p-4">
                              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                              <div className="row mb-3">
                                <div className="col-6">
                                  <label className="form-label" htmlFor="firstName">
                                    First Name <span className="man-start">*</span>
                                  </label>
                                  <Field className="form-control" type="text" id="firstName" name="firstName" />
                                  <ErrorMessage className="text-danger" name="firstName" component="div" />
                                </div>
                                <div className="col-6">
                                  <label className="form-label" htmlFor="lastName">
                                    Last Name <span className="man-start">*</span>
                                  </label>
                                  <Field className="form-control" type="text" id="lastName" name="lastName" />
                                  <ErrorMessage className="text-danger" name="lastName" component="div" />
                                </div>
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="username">
                                  Username <span className="man-start">*</span>
                                </label>
                                <Field className="form-control" type="text" id="username" name="username" />
                                <ErrorMessage className="text-danger" name="username" component="div" />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                  Email <span className="man-start">*</span>
                                </label>
                                <Field className="form-control" type="email" id="email" name="email" />
                                <ErrorMessage className="text-danger" name="email" component="div" />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" htmlFor="phone">
                                  Mobile Number <span className="man-start">*</span>
                                </label>
                                <Field
                                  className="form-control"
                                  maxLength={14}
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  onInput={(e) => {
                                    const inputValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
                                    const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                                    e.target.value = formattedValue;
                                  }}
                                />
                                <ErrorMessage name="phone" component="div" className="error-message" />
                              </div>
                              <div className="form-group mb-3">
                                <button
                                  className="btn btn-primary btn-lg w-100"
                                  type="button"
                                  onClick={submitForm} // Triggers Formik validation before calling handleCheckout
                                  disabled={isSubmitButtonDisabled}
                                >
                                  Continue
                                </button>
                                <div className="h-100 w-100 align-items-center justify-content-center mt-1 d-flex">
                                  Already have an account?{" "}
                                  <span>
                                    <a href="#" onClick={handleClickLogin}>
                                      Login
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                  {visiblePayment && (
                    <Stripe
                      setCheckout={userDetails.setCheckout}
                      userId={""}
                      userName={userDetails.userName}
                      userEmail={userDetails.userEmail}
                      address={userDetails.address}
                      phone={userDetails.phone}
                      full_name={userDetails.full_name}
                      handlePaymentBack={handlePaymentBack}
                      showBackBtn={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
