/**  Invesment API */
export const UPDATE_INVESTMENT_TAX_CHECK = 'investment/update-inv-tax-check'
export const CREATE_INVESTMENT = 'investment/create-stock';
export const GET_INVESTMENTS = 'investment/get-all-stocks';
export const GET_INVESTMENT_BY_ID = 'investment/get-stock';
export const UPDATE_INVESTMENT_BY_ID = 'investment/update-stock';
export const DELETE_INVESTMENT_BY_ID = 'investment/delete-investment';
export const ARCHIVE_INVESTMENT_BY_ID = 'investment/create-archive';
export const GET_ARCHIVED = 'investment/get-all-archives';
export const GET_STOCK_INV_INTEREST = 'investment/get-stock-inv-interest';
export const CREATE_STOCK_INV_INTEREST = 'investment/create-stock-inv-interest';
export const UPDATE_STOCK_INV_INTEREST = 'investment/update-stock-inv-interest';
export const DELETE_STOCK_INV_INTEREST = 'investment/delete-stock-inv-interest';

/** Real Estate */
export const CREATE_REAL_ESTATE_INVESTMENT = 'investment/create-real-estate';
export const GET_REAL_ESTATE_INVESTMENTS = 'investment/get-all-real-estate';
export const GET_REAL_ESTATE_INVESTMENT_BY_ID = 'investment/get-real-estate';
export const UPDATE_REAL_ESTATE_INVESTMENT_BY_ID = 'investment/update-real-estate';
export const GET_REAL_ESTATE_ARCHIVED = 'investment/get-real-estate-archived';
export const GET_REAL_ESTATE_COMPANY = 'investment/get-all-real-estate-company';
export const GET_REAL_ESTATE_BY_NAME = 'investment/get-real-estate-company';

/** Mineral Asset */
export const CREATE_MINERAL_ASSET = 'investment/create-mineral-asset';
export const GET_MINERAL_ASSETS = 'investment/get-all-mineral-asset';
export const GET_MINERAL_ASSET = 'investment/get-mineral-asset';
export const UPDATE_MINERAL_ASSET = 'investment/update-mineral-asset';
export const GET_MINERAL_ASSET_ARCHIVED = 'investment/get-mineral-assets-archived';

export const GET_PERSONAL_ASSETS = 'investment/get-all-personal-assets';
export const CREATE_PERSONAL_ASSETS = 'investment/create-personal-asset';
export const GET_PERSONAL_ASSETS_BY_ID = 'investment/get-personal-asset';
export const UPDATE_PERSONAL_ASSETS_BY_ID = 'investment/update-personal-asset';

export const GET_REAL_ESTATE_HOLDINGS = 'investment/get-all-real-estate-holdings';
export const GET_REAL_ESTATE_HOLDINGS_WITH_IE = 'investment/get-all-real-estate-holdings-with-ie';
export const CREATE_REAL_ESTATE_HOLDINGS = 'investment/create-real-estate-holding';
export const GET_REAL_ESTATE_HOLDINGS_BY_ID = 'investment/get-real-estate-holding';
export const UPDATE_REAL_ESTATE_HOLDINGS_BY_ID = 'investment/update-real-estate-holding';

/** User info */
export const GET_USER_PERSONAL_INFORMATION = 'investment/get-user-info';
export const UPDATE_USER_PERSONAL_INFORMATION = 'investment/update-user-info';
export const CHANGE_USER_PASSWORD = 'investment/change-user-password';

/** Income Expense */
export const GET_ALL_INCOME_EXPENSE = 'investment/get-all-income-expense';
export const CREATE_INCOME_EXPENSE = 'investment/create-income-expense';
export const GET_INCOME_EXPENSE = 'investment/get-income-expense';
export const UPDATE_INCOME_EXPENSE = 'investment/update-income-expense';
export const DELETE_INCOME_EXPENSE = 'investment/delete-income-expense';

/** Tenant */
export const GET_ALL_TENANT = 'investment/get-all-tenant';
export const CREATE_TENANT = 'investment/create-tenant';
export const GET_TENANT = 'investment/get-tenant';
export const UPDATE_TENANT = 'investment/update-tenant';
export const DELETE_TENANTE = 'investment/delete-tenant';

/** Login */
export const LOGIN_USER = 'user/login-user';
export const VERIFY_PASSWORD = 'user/verify-password';

/** Document Manager */
export const DOCUMENT_UPLOAD = 'document/upload-documents';
export const DOCUMENT_GET_BY_INVESTMENT_ID = 'document/get-documents-by-investment';
export const DOCUMENT_GET_BY_ID = 'document/get-document-by-id';
export const DELETE_DOCUMENT = 'document/delete-document';
export const GET_DOCUMENT_TYPE = 'document/get-document-type';
export const GET_DOCUMENT_LIST_BY_INV_ID = 'document/get-document-list-by-inv-id'

/** Profile photo upload */
export const PROFILE_PHOTO_UPLOAD = 'document/profile-image-upload';

/** Get overview data for dashboard */
export const GET_OVERVIEW_DATA = 'overview/get-investment-data';
export const GET_NET_WORTH_DATA = 'overview/get-net-worth-data';
export const GET_CONTINGENT_LIABILITY_DATA = 'overview/get-contingent-liability-data';

export const GET_USERS = 'user/get-all-users';
export const UPDATE_USER_PERMISSION = 'user/update-user-permission';
export const GET_USER_PERMISSION = 'user/get-user-permission';
export const GET_MY_PERMISSIONS = 'user/get-my-permissions';
export const GET_USER_INVESTMENT_PERMISSION = 'user/get-user-investment-permission';
export const GET_USER_INVESTMENTS_PERMISSION = 'user/get-user-investments-permission';
export const UPDATE_USER_INVESTMENT_PERMISSION = 'user/update-user-investment-permission';
export const GET_USERS_LIST = 'user/get-users-list';
export const GET_MANAGER_LIST = 'user/get-manager-list';
export const CREATE_USER = 'user/create-user';
export const GET_USER_BY_ID = 'user/get-user';
export const DELETE_USER = 'user/delete-user';
export const GET_MANAGERS = 'user/get-managers';
export const UPDATE_USER = 'user/update-user';
export const IMPERSONATE_USER = 'user/impersonate-user';
export const STOP_IMPERSONATE_USER = 'user/stop-impersonate-user';

//investpermsiiontable 
export const GET_USER_INVESTMENT_PERMISSIONS = 'user/get-user-investments';
export const UPDATE_USER_INVESTMENT_PERMISSIONS = 'user/update-user-investment-permissions';



export const GET_TRUSTEE_INFORMATION = 'user/get-trustee-info'
export const CREATE_TRUSTEE_INFORMATION = 'user/create-trustee-info'
export const UPDATE_TRUSTEE_INFORMATION ='user/update-trustee-info'

/** Set password */
export const SET_PASSWORD = 'user/set-password';
export const SET_PASSWORD_TOKEN_VERIFY = 'user/verify-token';
export const FORGET_PASSWORD = 'user/forget-password';
export const RESET_PASSWORD = 'user/reset-password';
export const SEND_INVITATION = 'user/send-invitation';
export const USER_STATUS = 'user/user-status';
export const UPDATE_USER_STATUS = 'user/update-user-status';

/** Permission list */
export const GET_PERMISSIONS_LIST = 'user/get-permission-list';
/** Get Audit Log */
export const GET_AUDIT_LOG_BY_INVESTMENT_ID = 'investment/get-auditlogs';

/** Estate Planning Document Manager */
export const GET_ALL_ESTATE_PLANNING = 'estate-planning/get-documents-by-owner-id'
export const ESTATE_PLANNING_DOCUMENT_UPLOAD = 'estate-planning/upload-documents';
export const ESTATE_PLANNING_DOCUMENT_GET_BY_ID = 'estate-planning/get-document-by-id';
export const ESTATE_PLANNING_DELETE_DOCUMENT = 'estate-planning/delete-document';
export const ESTATE_PLANNING_TYPES = 'estate-planning/get-estate-planning-types';

/** Operating Business   */
export const GET_ALL_OPERATING_BUSINESS = 'operating-business/get-all'
export const CREATE_OPERATING_BUSINESS = 'operating-business/create';
export const UPDATE_OPERATING_BUSINESS = 'operating-business/update';
export const GET_OPERATING_BUSINESS = 'operating-business/get-operating-business';
export const OPERATING_BUSINESS_DELETE = 'operating-business/delete-operating-business'; 
export const OPERATING_BUSINESS_ARCHIVE = 'operating-business/archive';

/** Operating Business Year */
export const GET_ALL_OPERATING_BUSINESS_YEAR = 'operating-business/get-all-operating-business-years';
export const GET_OPERATING_BUSINESS_YEAR = 'operating-business/get-operating-business-year';
export const CREATE_OPERATING_BUSINESS_YEAR = 'operating-business/create-operating-business-year';
export const UPDATE_OPERATING_BUSINESS_YEAR = 'operating-business/update-operating-business-year';
export const DOCUMENT_GET_BY_OB_YEAR_ID = 'operating-business/document-get-by-ob-year-id';
export const YEAR_DOCUMENT_UPLOAD = 'operating-business/year-document-upload';
export const GET_YEAR_DOCUMENT_BY_ID = 'operating-business/get-document-by-id';
export const DELETE_YEAR_DOCUMENT = 'operating-business/delete-year-document';
export const DELETE_OPERATING_BUSINESS_YEAR = 'operating-business/delete-business-year';

/** Reminder */
export const GET_REMINDER_LIST = 'reminder/get-reminder-list';
export const GET_REMINDER_LISTS = 'reminder/get-reminder-lists';
export const GET_REMINDER_TASK_LIST = 'reminder/get-reminder-task-list';
export const GET_ALL_REMINDER_TASK_LIST = 'reminder/get-all-reminder-task-list';
export const UPDATE_REMINDER_STATUS = 'reminder/update-reminder-status';
export const CREATE_REMINDER = 'reminder/create-reminder';
export const UPDATE_REMINDER = 'reminder/update-reminder';
export const DELETE_REMINDER_BY_ID = 'reminder/delete-reminder';
export const GET_REMINDER_BY_INV_ID = 'reminder/get-reminder-by-rec-id';
export const COMMON_REMINDER_STATUS_UPDATE = 'reminder/common-reminder-status-update';


/** SinUP */
export const USER_NAME_VALIDATION ='user/user-name-validation'

/** Stripe end points */
export const GET_ACTIVE_STRIPE_PRODUCT = 'payment/get-products';
export const CREATE_STRIPE_CHECKOUT = 'payment/create-checkout';
export const GET_DEATAILS_CHECKOUT = 'payment/session-details';
export const GET_USER_STRIPE_SUBSCRIPTION_DETAIL = '/payment/get-subscription-details';
export const GET_USER_SUBSCRIPTION_LIST = 'payment/get-user-subscription-list';
export const UPDATE_PAYMENT_METHODE = 'payment/update-payment-method';

/**Cash Accounts  */
export const DELETE_CASH_ACCOUNTS = 'investment/delete-cash-account';
export const GET_CASH_VALUE_BY_IDS = 'investment/cash-value-of-investments';
