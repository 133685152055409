import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserPermissionStore } from '../Store/UserPermission';
import Notifications from './Notification';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReminderTaskModel from './Reminder/ReminderTaskModel';
import { GET_REMINDER_LIST, STOP_IMPERSONATE_USER } from '../api/endpoints';
import { get, post } from '../api';
// import { handleExport } from './exportData';
import { PageLoader } from '../Context/PageLoader';
import { ActionIcon } from '../utils/commonUtils';


function Header(props) {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const recordID = parts[2];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [reminderIconState, setReminderState] = useState(false);
    const dropdownRef = useRef(null);
    const openProfileRef = useRef(null);
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);

    const headerMapping = {
        'portfolio-summary': 'Portfolio Summary',
        'liquid-asset-list': 'Liquid Assets',
        'view-liquid-asset': 'Liquid Assets',
        'edit-liquid-asset': 'Liquid Assets',
        'add-liquid-asset': 'Liquid Assets',
        'archived-list': 'Archived',
        'private-equity-list': 'Private Equity',
        'view-private-equity': 'Private Equity',
        'edit-private-equity': 'Private Equity',
        'add-private-equity': 'Private Equity',
        'royalty-income-list': 'Royalty Income',
        'view-royalty-income': 'Royalty Income',
        'edit-royalty-income': 'Royalty Income',
        'add-royalty-income': 'Royalty Income',
        'personal-assets-list': 'Personal Assets',
        'view-personal-assets': 'Personal Assets',
        'edit-personal-assets': 'Personal Assets',
        'add-personal-assets': 'Personal Assets',
        'real-estate-holding-list': 'Real Estate Holdings',
        'view-real-estate-holdings': 'Real Estate Holdings',
        'edit-real-estate-holdings': 'Real Estate Holdings',
        'add-income-expense/': 'Real Estate Holdings',
        'edit-income-expense': 'Real Estate Holdings',
        'view-income-expense': 'Real Estate Holdings',
        'add-real-estate-holdings': 'Real Estate Holdings',
        'users': 'User Management',
        'user-info': 'User Management',
        'estate-planning': 'Estate Planning',
        'operating-business': 'Operating Business',
        'operating-business-view': 'Operating Business',
        'view-operating-business': 'Operating Business',
        'add-operating-business': 'Operating Business',
        'edit-operating-business': 'Operating Business',
        'trustee-instructions': 'Trustee Instructions',
        'report': 'Report',
        'reminder-list': 'Reminder List',
    };

    let setHeaderName = headerMapping[urlName] || '';

    const private_equity_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const liquid_asset_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const royalty_income_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
    const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;

    const [openProfile, setOpenProfile] = useState(false);
    const history = useNavigate();
    const [profileUrl, setProfileUrl] = useState(false);
    const { clearPermissions } = UserPermissionStore();
    const [collapsed, setCollapsed] = useState(true);
    const [categoryID, setCategoryID] = useState('');
    const [entityType, setEntityType] = useState('');
    const [modelShow, setModelShow] = useState(false);
    const [count, setCount] = useState('');
    const [impersonateUser, setImpersonateUser] = useState('Deactive');

    const handleSignOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('authToken');
        clearPermissions();
        localStorage.removeItem('user-permission');
        // history('login');
        window.location.href = '/login';
    };

    useEffect(() => {
        const profileUrlImage = localStorage.getItem('profileImageUrl');
        if (!profileUrl) {
            setProfileUrl(profileUrlImage ? JSON.parse(profileUrlImage) : false);
        }
        const tempImpersonateMode = localStorage.getItem('impersonateMode');
        setImpersonateUser(tempImpersonateMode && tempImpersonateMode === "Active" ? true : false);

    }, [])

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
        if (openProfileRef.current && !openProfileRef.current.contains(event.target)) {
            setOpenProfile(false);
        }
    };

    useEffect(() => {
        if (isDropdownOpen || openProfile) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpen, openProfile]);

    const toggleDropdown = () => {
        switch (setHeaderName) {
            case 'Portfolio Summary':
                setIsDropdownOpen(!isDropdownOpen);
                break;
            case 'Liquid Assets':
                setCategoryID(liquid_asset_master);
                setEntityType('');
                setModelShow(true);
                break;
            case 'Private Equity':
                setCategoryID(private_equity_master);
                setEntityType('');
                setModelShow(true);
                break;
            case 'Real Estate Holdings':
                setCategoryID(real_estate_holding_master);
                setEntityType('');
                setModelShow(true);
                break;
            case 'Royalty Income':
                setCategoryID(royalty_income_master);
                setEntityType('');
                setModelShow(true);
                break;
            case 'Personal Assets':
                setCategoryID(personal_asset_master);
                setEntityType('');
                setModelShow(true);
                break;
            case 'Operating Business':
                setEntityType('operating_business');
                setCategoryID('');
                setModelShow(true);
                break;
            case 'Estate Planning':
                setEntityType('estate_planning');
                setCategoryID('');
                setModelShow(true);
                break;
            case 'Reminder List':
                setIsDropdownOpen(!isDropdownOpen);
                break;
            default:
                break;
        }
    };

    const toggleOpenProfile = () => {
        setOpenProfile(!openProfile);
    };

    const handlePopup = (value) => {
        setIsDropdownOpen(value);
    }

    const reminderState = (value) => {
        // setReminderState(value);
    }

    useEffect(() => {
        setCollapsed(!props.menuCollapsed);
    }, [props.menuCollapsed]);
    const collapsedValue = collapsed;

    useEffect(() => {
        getCount(setHeaderName);
    }, [setHeaderName, location]);

    const getCount = async (name) => {
        let tempCat = '';
        let tempEntityType = '';
        let tempInvestId = recordID;
        switch (name) {
            case 'Portfolio Summary':
                tempCat = '';
                tempEntityType = '';
                // setIsDropdownOpen(!isDropdownOpen);
                break;
            case 'Liquid Assets':
                tempCat = liquid_asset_master;
                break;
            case 'Private Equity':
                tempCat = private_equity_master;
                break;
            case 'Real Estate Holdings':
                tempCat = real_estate_holding_master;
                break;
            case 'Royalty Income':
                tempCat = royalty_income_master;
                break;
            case 'Personal Assets':
                tempCat = personal_asset_master;
                break;
            case 'Operating Business':
                tempEntityType = 'operating_business';
                break;
            case 'Estate Planning':
                tempEntityType = 'estate_planning';
                break;
            case 'Reminder List':
                tempCat = '';
                tempEntityType = '';
                // setIsDropdownOpen(!isDropdownOpen);
                break;
            case 'Trustee Instructions':
                tempEntityType = 'trustee-instructions';
                break;
            case 'Report':
                tempEntityType = 'report';
                break;
            case 'User Management':
                tempEntityType = 'users';
                break;
            case 'User Management':
                tempEntityType = 'user_info';
                break;
            case 'Archived':
                tempEntityType = 'archived-list';
                break;
            default:
                break;
        }

        try {
            let payload = {
                investment_cat_id: tempCat,
                entity_type: tempEntityType,
                investment_id: tempInvestId,
            };
            const reminderTasks = await post(GET_REMINDER_LIST, payload);
            if (!tempCat && !tempEntityType) {
                setCount(reminderTasks.data.total_count);
                setReminderState(reminderTasks.data.total_count > 0);
            } else {
                setCount(reminderTasks.data.reminderList.length);
                setReminderState(reminderTasks.data.reminderList.length > 0);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleClose = () => setModelShow(false);

    const stopUserImpersonate = async() => {
        try {
            const userStopImpersonate = await get(STOP_IMPERSONATE_USER);
            const authToken = {
                token: userStopImpersonate?.data?.token,
                refreshToken: userStopImpersonate?.data?.refreshToken,
              };
              localStorage.setItem('authToken', JSON.stringify(authToken));
              localStorage.setItem('profileImageUrl', JSON.stringify(userStopImpersonate.data?.userData?.profileImagePath || ''));
              localStorage.setItem('user-permission', '')
              localStorage.setItem('impersonateMode', 'Deactive');
              window.location.reload();
        } catch (error) {
            console.log('Error in the stopUserImpersonate function: ',error )
        }
    }

    return (
        <header className="top-header-area d-flex align-items-center justify-content-between no-print">
            <div className="left-side-content-area d-flex align-items-center">
                <button id="notification-btn-fetch" onClick={() => getCount(setHeaderName)} style={{ display: 'none' }}> Click me </button>
                <div className="mobile-logo">
                    {/* <a href="index.html"><img src="img/core-img/small-logo.png" alt="Mobile Logo" /></a> */}
                </div>
                <div className="flapt-triggers">
                    <div className="menu-collasped" id="menuCollasped">
                        <OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-bottom`}>{collapsedValue ? 'Hide menu' : 'Show menu'}</Tooltip>}>
                            <i className='bx bx-grid-alt' onClick={props.toggleCollapse} ></i>
                        </OverlayTrigger>
                    </div>
                    <div className="mobile-menu-open" id="mobileMenuOpen">
                        <i className='bx bx-grid-alt' onClick={props.toggleCollapse}></i>
                    </div>
                </div>
                {/* <ul className="left-side-navbar d-flex align-items-center">
                        <li className="hide-phone app-search">
                            <input type="text" className="form-control" placeholder="Search..." />
                            <span className="bx bx-search-alt"></span>
                        </li>
                    </ul> */}
            </div>
            <div className='align-items-center'>
                <h6 className='mb-0'>{setHeaderName}</h6>
            </div>

            <div className="right-side-navbar d-flex align-items-center justify-content-end">

                <div className="right-side-trigger" id="rightSideTrigger">
                    <i className='bx bx-menu-alt-right'></i>
                </div>
                <ul className="right-side-content d-flex align-items-center">
                    <li className="nav-item dropdown">
                        {/* <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"><span><i
                                        className='bx bx-world'></i></span></button> */}
                        <div className="dropdown-menu language-dropdown dropdown-menu-right">
                            <div className="user-profile-area">
                                <a href="#" className="dropdown-item mb-15"><img src="img/core-img/l5.jpg" alt="Image" />
                                    <span>USA</span></a>
                                <a href="#" className="dropdown-item mb-15"><img src="img/core-img/l2.jpg" alt="Image" />
                                    <span>German</span></a>
                                <a href="#" className="dropdown-item mb-15"><img src="img/core-img/l3.jpg" alt="Image" />
                                    <span>Italian</span></a>
                                <a href="#" className="dropdown-item"><img src="img/core-img/l4.jpg" alt="Image" />
                                    <span>Russian</span></a>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        {/* <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"><i className='bx bx-envelope'></i></button> */}
                        <div className="dropdown-menu dropdown-menu-right">
                            <div className="top-message-area">
                                <div className="message-heading">
                                    <div className="heading-title">
                                        <h6 className="mb-0">All Messages</h6>
                                    </div>
                                    <span>10</span>
                                </div>

                                <div className="message-box" id="messageBox">
                                    <a href="#" className="dropdown-item">
                                        <i className='bx bx-dollar-circle'></i>
                                        <div>
                                            <span>Did you know?</span>
                                            <p className="mb-0 font-12">Adipisicing elit. Ipsa, porro!</p>
                                        </div>
                                    </a>

                                    <a href="#" className="dropdown-item">
                                        <i className='bx bx-shopping-bag'></i>
                                        <div>
                                            <span>Congratulations!
                                            </span>
                                            <p className="mb-0 font-12">Consectetur adipisicing elit.</p>
                                        </div>
                                    </a>

                                    <a href="#" className="dropdown-item">
                                        <i className='bx bx-wallet-alt'></i>
                                        <div>
                                            <span>Hello Obeta</span>
                                            <p className="mb-0 font-12">Consectetur adipisicing elit.</p>
                                        </div>
                                    </a>

                                    <a href="#" className="dropdown-item">
                                        <i className='bx bx-border-all'></i>
                                        <div>
                                            <span>Your order is placed</span>
                                            <p className="mb-0 font-12">Consectetur adipisicing elit.</p>
                                        </div>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        <i className='bx bx-wallet-alt'></i>
                                        <div>
                                            <span>Haslina Obeta</span>
                                            <p className="mb-0 font-12">Consectetur adipisicing elit.</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>

                    {impersonateUser && <li className="nav-item dropdown" ref={dropdownRef}>
                        <ActionIcon
                            iconClass="zmdi zmdi-accounts impersonate-icon"
                            tooltip={`Stop User Impersonation`}
                            onClick={stopUserImpersonate}
                            backgroundColor="#74788d"
                            placement='bottom'
                        />
                    </li>}

                    <li className="nav-item dropdown" ref={dropdownRef}>
                        <button type="button" className={`btn dropdown-toggle ${isDropdownOpen ? 'show' : ''}`} onClick={toggleDropdown} data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded={isDropdownOpen}><i className={`bx bx-bell ${reminderIconState ? 'bx-tada' : ''}`}></i> {reminderIconState ?
                                <span className="count-badge" style={{
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '-10px',
                                    background: '#e82529',
                                    color: 'white',
                                    borderRadius: '50%',
                                    padding: '1px 5px',
                                    fontSize: '10px',
                                    width: 'auto',
                                    height: '20px',
                                    zIndex: '1',
                                    lineHeight: '18px',
                                    minWidth: '20px'
                                }}>
                                    {count}
                                </span> : ''}</button>
                        <div className={`dropdown-menu dropdown-menu-right ${isDropdownOpen ? 'show' : ''}`} style={{ inset: '0px auto auto 0px' }} >

                            <Notifications handlePopup={handlePopup} reminderState={reminderState} />
                        </div>
                    </li>

                    <li
                        className="nav-item dropdown"
                        ref={openProfileRef}
                        onMouseEnter={() => setOpenProfile(true)}
                        onMouseLeave={() => setOpenProfile(false)}
                    >
                        <button
                            type="button"
                            className={`btn dropdown-toggle ${openProfile ? 'show' : ''}`}
                            onClick={toggleOpenProfile}
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded={openProfile ? 'true' : 'false'}
                        >
                            <img
                                className='profile-header-icon'
                                id='userProfile'
                                src={profileUrl ? profileUrl : "/img/profile-image.png"}
                                alt=""
                                style={{ height: '32px', width: '32px' }}
                            />
                        </button>
                        <div className={`dropdown-menu profile dropdown-menu-right profile-dropdown ${openProfile ? 'show' : ''}`}>
                            <div className='header-profile-empty-space'></div>
                            <div className="user-profile-area">
                                <a href="/profile" className="dropdown-item">
                                    <i className="bx bx-user font-15" aria-hidden="true"></i> My Account
                                </a>
                                <a href="#" className="dropdown-item" onClick={handleSignOut}>
                                    <i className="bx bx-power-off font-15" aria-hidden="true"></i> Sign-out
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ReminderTaskModel show={modelShow} investmentID={recordID} investmentCatID={categoryID} entityType={entityType} handleClose={handleClose} />
        </header>
    )
}
export default Header;
