import React, { useState, useEffect, useContext } from 'react';
import { PageLoader } from '../Context/PageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from './WMS.png'; // Ensure this is the path to your logo file
import { string } from 'yup';
import { GET_DEATAILS_CHECKOUT, SEND_INVITATION } from '../api/endpoints';
import { concatUrlWithId } from '../utils/commonUtils';
import { get } from '../api';

function PaymentSuccess() {
    const { loading, setLoading } = useContext(PageLoader);
    const [countdown, setCountdown] = useState(40);
    const [searchParams] = useSearchParams();
    const history = useNavigate();

    useEffect(() => {
        setLoading(false);
        fetchPaymentDetails();
        // const timer = setInterval(() => {
        //     setCountdown((prevCountdown) => prevCountdown - 1);
        // }, 1000);

        // if (countdown === 0) {
        //     clearInterval(timer);
        //     history('/home'); // Redirect to the homepage
        // }

        // return () => clearInterval(timer);
    }, [countdown, history]);


    const fetchPaymentDetails = async () => {

        const id = searchParams.get('session_id')


        //    console.log(GET_DEATAILS_CHECKOUT, `?session_id=${id}`)
        if (id) {
            const requestBody = { session_id: id };

            const session = await get(GET_DEATAILS_CHECKOUT, requestBody);
            // if (session.details.user) {
            //     let tempURL = SEND_INVITATION;
            //     const sendInvitation = await get(concatUrlWithId(tempURL, session.details.user));                
            // }
        }
    }

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
                <img src={logo} alt="Logo" className="mb-4" style={{ width: '150px' }} />
                <h2 className="text-success">🎉 Payment Successful!</h2>
                {/* <p className="mt-3">Thank you for your payment. Your transaction was completed successfully.</p> */}
                <p className="mt-3 " style={{ width: '550px' }} >Your account has been created, and a password reset email will be sent shortly. Please check your inbox to reset your password and continue the process.</p>
                <br></br>
                <p className="mt-3">If you don't see the email, kindly check your spam folder as well.</p>
                {/* <p className="text-muted">
                    You will be redirected to the homepage in <strong>{countdown}</strong> seconds.
                </p>
                <div className="spinner-border text-success mt-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> */}
            </div>
        </div>
    );
}

export default PaymentSuccess;
