import React, { useContext, useEffect, useRef, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import PieChart from "../../components/PieChart";
import Pdf_GenerateHelper from "./helper/Pdf_GenerateHelper";
import html2canvas from "html2canvas";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { PageLoader } from "../../Context/PageLoader";
import { get } from "../../api";
import {
  GET_OVERVIEW_DATA,
  GET_INVESTMENTS,
  GET_REAL_ESTATE_HOLDINGS,
  GET_ALL_OPERATING_BUSINESS,
  GET_REAL_ESTATE_INVESTMENTS,
  GET_PERSONAL_ASSETS,
  GET_MINERAL_ASSETS,
  GET_NET_WORTH_DATA,
  GET_ALL_ESTATE_PLANNING,
  GET_REAL_ESTATE_COMPANY,
} from "../../api/endpoints";
import companyLogo from "../../pages/WMS.png";
import BarChart from "../../components/BarChart";
import PieChartV2 from "../../components/PieChartV2";
import { columnConfigs } from "../../utils/exportConfig";
import { formatCurrency } from "../../utils/commonUtils";

const Pdf_ReportGenerator = ({ selectedSubscriber }) => {
  const [chartImages, setChartImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const chartRefs = useRef({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isBlurred, setIsBlurred] = useState(false);
  const { loading, setLoading } = useContext(PageLoader);
  const [pageConfigurations, setPageConfigurations] = useState([]);

  useEffect(() => {
    if (pageConfigurations.length > 1 && !isGenerating) {
      generatePDF();
    }
  }, [pageConfigurations]);

  const generatePDF = async () => {
    try {
      setIsGenerating(true);
      setIsBlurred(true);

      await new Promise((resolve) => setTimeout(resolve, 3000));
      console.log("pageConfigurations==>2==> ", pageConfigurations);

      let processedCharts = 0;
      const batchSize = 2;

      let totalCharts = pageConfigurations.reduce(
        (sum, config) => sum + (config.data ? config.data.length : 0),
        0
      );

      let remainingProgress = 80;

      // Process charts in batches
      const processChartsInBatches = async (configs) => {
        for (const config of configs) {
          if (!config.data) continue;

          const updatedData = [];
          for (let i = 0; i < config.data.length; i += batchSize) {
            const batch = config.data.slice(i, i + batchSize);

            const batchResults = await Promise.all(
              batch.map(async (chart, idx) => {
                const chartElement =
                  chartRefs.current[`${config.title}-${i + idx}`];

                if (chartElement) {
                  await new Promise((resolve) => setTimeout(resolve, 150));

                  const canvas = await html2canvas(chartElement, {
                    scale: 1.4,
                    useCORS: true,
                    logging: false,
                    allowTaint: true,
                    imageTimeout: 15000,
                  });

                  const chartImgSrc = canvas.toDataURL("image/png");
                  processedCharts++;

                  // Dynamically calculate progress
                  let progressValue =
                    progress +
                    Math.round(
                      (processedCharts / totalCharts) * remainingProgress
                    );
                  setProgress(progressValue);

                  return { ...chart, chartImgSrc };
                }
                return chart;
              })
            );

            updatedData.push(...batchResults);
          }

          config.data = updatedData;
        }

        return configs;
      };

      // Process charts in batches and update configurations
      const updatedPageConfigurations = await processChartsInBatches(
        pageConfigurations
      );
      setPageConfigurations(updatedPageConfigurations);

      console.log("updatedPageConfigurations===> ", updatedPageConfigurations);

      setProgress(95);

      // Generate the PDF
      const doc = (
        <Pdf_GenerateHelper
          pageConfigurations={updatedPageConfigurations}
          companyLogo={companyLogo}
        />
      );
      const blob = await pdf(doc).toBlob();

      setProgress(100);

      // Trigger download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "report.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setIsBlurred(false);
      setLoading(false);
    } finally {
      setIsGenerating(false);
      setShowModal(false);
      setIsBlurred(false);
      setLoading(false);
      setPageConfigurations([]);
    }
  };

  const fetchData = async () => {
    try {
      const requestUser = selectedSubscriber
        ? { user: selectedSubscriber }
        : {};
      const [
        overviewData,
        investmentData,
        realEstateHoldings,
        privateEquityInvestments,
        operatingBusiness,
        personalAssets,
        royaltyIncome,
        netWorthData,
        EstatePlannig,
        estatePlannigChartData,
        CompanyInvesting,
      ] = await Promise.all([
        get(GET_OVERVIEW_DATA, requestUser),
        get(GET_INVESTMENTS, requestUser),
        get(GET_REAL_ESTATE_HOLDINGS, requestUser),
        get(GET_REAL_ESTATE_INVESTMENTS, requestUser),
        get(GET_ALL_OPERATING_BUSINESS, requestUser),
        get(GET_PERSONAL_ASSETS, requestUser),
        get(GET_MINERAL_ASSETS, requestUser),
        get(GET_NET_WORTH_DATA, requestUser),
        get(GET_ALL_ESTATE_PLANNING, requestUser),
        get(GET_OVERVIEW_DATA, { ...requestUser, isTaxData: true }),
        get(GET_REAL_ESTATE_COMPANY, requestUser),
      ]);

      const filteredData =
        overviewData?.investmentData?.filter(
          (item) => item.current_value !== null
        ) || [];
      const updatedOverviewList = filteredData.map((item) => ({
        ...item,
        "investment_categories.value":
          item["investment_categories.value"] === "Royalty Income"
            ? "Royalty Interest"
            : item["investment_categories.value"],
        createdAt: item.createdAt ? formatDate(item.createdAt, "date") : null,
        updatedAt: item.updatedAt ? formatDate(item.updatedAt, "date") : null,
        cal_current_valuation: formatCurrency(item.cal_current_valuation),
      }));

      const updatedProjectedIncome = overviewData?.projectIncome
        ? Object.entries(overviewData.projectIncome).reduce(
            (acc, [key, value]) => {
              acc[key === "Royalty Income" ? "Royalty Interest" : key] = value;
              return acc;
            },
            {}
          )
        : {};

      const updatedChartData =
        overviewData?.chartData?.map((item) => ({
          ...item,
          asset_type:
            item.asset_type === "Royalty Income"
              ? "Royalty Interest"
              : item.asset_type,
        })) || [];
      console.log("updatedLiquidAsset ...! :", investmentData);
      const updatedLiquidAsset = {
        ...investmentData,
        investments: Array.isArray(investmentData.investments)
          ? investmentData.investments.map((item) => ({
              ...item,
              current_value: formatCurrency(item.current_value),
              interest_earning_rate: formatPercentage(
                item.interest_earning_rate
              ),
              ownership_percentage: formatPercentage(item.ownership_percentage),
              projected_annual_income: formatCurrency(
                item.projected_annual_income
              ),
              first_activity_date: item.first_activity_date
                ? formatDate(item.first_activity_date, "date")
                : null,
              cal_current_value: formatCurrency(item.cal_current_value),
            }))
          : [],
      };
      const updatedRealEstateHoldings = {
        ...realEstateHoldings,
        investments: Array.isArray(realEstateHoldings.investments)
          ? realEstateHoldings.investments.map((item) => ({
              ...item,
              projected_annual_income: formatCurrency(
                item.projected_annual_income
              ),
              amount_invested: formatCurrency(item.amount_invested),
              current_valuation: formatCurrency(item.current_valuation),
              cal_current_value: formatCurrency(item.cal_current_value),
              projected_annual_income: formatCurrency(
                item.projected_annual_income
              ),
              maturity_date:item.maturity_date ?formatDate(item.maturity_date, "date"): null,
              building_square_feet:formatCurrency(item.building_square_feet),
              parcel_size_acreage:formatCurrency(item.parcel_size_acreage),
              amount_borrowed:formatCurrency(item.amount_borrowed),
            }))
          : [],
      };
      const updatedprivateEquityInvestments = {
        ...privateEquityInvestments,
        investments: Array.isArray(privateEquityInvestments.investments)
          ? privateEquityInvestments.investments.map((item) => ({
              ...item,
              investment_date: item.investment_date
                ? formatDate(item.investment_date, "date")
                : null,
              ownership_percentage: formatPercentage(item.ownership_percentage),
              preferred_return_interest: formatPercentage(item.preferred_return_interest),
              equity_debt_invested: formatCurrency(item.equity_debt_invested),
              cal_current_value: formatCurrency(item.cal_current_value),
              projected_annual_income: formatCurrency(
                item.projected_annual_income
              ),
              maturity_date:item.maturity_date ?formatDate(item.maturity_date, "date"): null,
              amount_borrowed:formatCurrency(item.amount_borrowed),
            }))
          : [],
      };

      const transformedOperatingBusinessData = operatingBusiness?.data?.map(
        (item) => ({
          ...item,
          status: item.status ? "Active" : "Inactive",
          createdAt: item.createdAt ? formatDate(item.createdAt, "date") : null,
          updatedAt: item.updatedAt ? formatDate(item.updatedAt, "date") : null,
          maturity_date:item.maturity_date ?formatDate(item.maturity_date, "date"): null,
          projected_annual_income: formatCurrency(item.projected_annual_income),
          estimated_value: formatCurrency(item.estimated_value),
          total_assets: formatCurrency(item.total_assets),
          total_liabilities: formatCurrency(item.total_liabilities),
          ownership_percentage: formatPercentage(item.ownership_percentage),  
        })
      );

      const updatedRoyaltyIncome = {
        ...royaltyIncome,
        investments: Array.isArray(royaltyIncome.investments)
          ? royaltyIncome.investments.map((item) => ({
              ...item,
              percentage_interest: formatPercentage(item.percentage_interest),
              amount_invested: formatCurrency(item.amount_invested),
              cal_current_value: formatCurrency(item.cal_current_value),
              projected_annual_income: formatCurrency(
                item.projected_annual_income
              ),
            }))
          : [],
      };
      const updatedPersonalAssets = {
        ...personalAssets,
        investments: Array.isArray(personalAssets.investments)
          ? personalAssets.investments.map((item) => ({
              ...item,
              current_value: formatCurrency(item.current_value),
              amount_borrowed: formatCurrency(item.amount_borrowed),
              maturity_date:item.maturity_date ?formatDate(item.maturity_date, "date"): null,
            }))
          : [],
      };
      const UpdatedEstatePlanning = {
        ...EstatePlannig,
        data: Array.isArray(EstatePlannig.data)
          ? EstatePlannig.data.map((item) => ({
              ...item,
              createdAt: item.createdAt
                ? formatDate(item.createdAt, "date")
                : null,
              updatedAt: item.updatedAt
                ? formatDate(item.updatedAt, "date")
                : null,
              get_type_name: item.get_type?.name || null,
            }))
          : [],
      };
      console.log("UpdatedEstatePlaniing :: ", UpdatedEstatePlanning);

      return {
        updatedOverviewList,
        updatedProjectedIncome,
        updatedChartData,
        updatedLiquidAsset,
        updatedRealEstateHoldings,
        updatedprivateEquityInvestments,
        transformedOperatingBusinessData,
        updatedPersonalAssets,
        updatedRoyaltyIncome,
        netWorthData,
        operatingBusiness,
        UpdatedEstatePlanning,
        estatePlannigChartData,
        CompanyInvesting,
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const formatPercentage = (value) => {
    if (value === null || value === undefined || value === 0) {
      return "0.00";
    }
    return value;
  };

  const generatePDFReport = async () => {
    try {
      setProgress(1);
      // setLoading(true);
      setIsBlurred(true);
      const {
        updatedOverviewList,
        updatedProjectedIncome,
        updatedChartData,
        updatedLiquidAsset,
        updatedRealEstateHoldings,
        updatedprivateEquityInvestments,
        transformedOperatingBusinessData,
        updatedPersonalAssets,
        updatedRoyaltyIncome,
        operatingBusiness,
        UpdatedEstatePlanning,
        estatePlannigChartData,
        CompanyInvesting,
      } = await fetchData();
      console.log(
        "transformedOperatingBusinessData ::: ",
        updatedprivateEquityInvestments
      );
      console.log("Updated Chart Data :::: ", updatedPersonalAssets);
      setProgress(5);
      const newPageConfigurations = [
        {
          title: "Intro",
          leftContent: "",
          chartType: "",
          chartWidth: "",
          data: null,
          tableColumns: null,
          tableData: null,
          imgSrc: companyLogo,
          pageNumber: 0,
        },
        {
          title: "Portfolio Allocation",
          leftContent: "Overview of your portfolio",
          data: [
            {
              chartId: "001",
              chartType: "Pie2Chart",
              chartTitle: "Portfolio Allocation",
              chartData: updatedChartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Valuation", "Equity Invested"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "002",
              chartType: "Bar",
              chartTitle: "Portfolio Summary Performance",
              chartData: updatedChartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Valuation", "Equity Invested"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "003",
              chartType: "Pie",
              chartTitle: "Net Worth",
              chartData: updatedOverviewList,
              labelKey: "investment_categories.value",
              pieChartType: "net_worth",
              seriesKey: ["current_value', 'amount_borrowed"],
              seriesName: ["Current Valuation", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "004",
              chartType: "Pie",
              chartTitle: "Projected Annual Income",
              chartData: updatedProjectedIncome,
              labelKey: "investment_categories.value",
              pieChartType: "project_income",
              seriesKey: "",
              seriesName: "",
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.portfolio,
          tableData: updatedOverviewList,
          headerObjValue: [],
          imgSrc: null,
          pageNumber: 1,
        },
        {
          title: "Liquid Assets",
          leftContent: "",
          data: [
            {
              chartId: "005",
              chartType: "Pie2Chart",
              chartTitle: "Liquid Assets Allocation",
              chartData: updatedLiquidAsset.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Value", "Invested Value"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "006",
              chartType: "Bar",
              chartTitle: "Liquid Assets Summary Performance",
              chartData: updatedLiquidAsset.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Value", "Invested Value"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.liquid,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(updatedLiquidAsset.totals?.total_invested),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                updatedLiquidAsset.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                updatedLiquidAsset.totals?.total_inv_aft_Debt
              ),
            },
          ],
          tableData: updatedLiquidAsset.investments,
          imgSrc: null,
          pageNumber: 2,
        },
        {
          title: "Real Estate Holdings",
          leftContent: "",
          data: [
            {
              chartId: "007",
              chartType: "Pie2Chart",
              chartTitle: "Real Estate Holdings Allocation",
              chartData: updatedRealEstateHoldings.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "008",
              chartType: "Bar",
              chartTitle: "Real Estate Holdings Summary Performance",
              chartData: updatedRealEstateHoldings.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.realEstateHolding,
          tableData: updatedRealEstateHoldings.investments,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(
                updatedRealEstateHoldings.totals?.total_invested
              ),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                updatedRealEstateHoldings.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                updatedRealEstateHoldings.totals?.total_inv_aft_Debt
              ),
            },
          ],
          imgSrc: null,
          pageNumber: 3,
        },
        {
          title: "Private Equity",
          leftContent: "",
          data: [
            {
              chartId: "009",
              chartType: "Pie2Chart",
              chartTitle: "Private Equity Allocation",
              chartData: updatedprivateEquityInvestments.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "010",
              chartType: "Bar",
              chartTitle: "Private Equity Summary Performance",
              chartData: updatedprivateEquityInvestments.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.PrivateEquity,
          tableData: updatedprivateEquityInvestments.investments,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(
                updatedprivateEquityInvestments.totals?.total_invested
              ),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                updatedprivateEquityInvestments.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                updatedprivateEquityInvestments.totals?.total_inv_aft_Debt
              ),
            },
          ],
          imgSrc: null,
          pageNumber: 4,
        },
        {
          title: "Royalty Income",
          leftContent: "",
          data: [
            {
              chartId: "011",
              chartType: "Pie2Chart",
              chartTitle: "Royalty Income Allocation",
              chartData: updatedRoyaltyIncome.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "012",
              chartType: "Bar",
              chartTitle: "Royalty Income Summary Performance",
              chartData: updatedRoyaltyIncome.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Investment Value", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.royaltyIncome,
          tableData: updatedRoyaltyIncome.investments,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(
                updatedRoyaltyIncome.totals?.total_invested
              ),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                updatedRoyaltyIncome.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                updatedRoyaltyIncome.totals?.total_inv_aft_Debt
              ),
            },
          ],
          imgSrc: null,
          pageNumber: 5,
        },
        {
          title: "Personal Assets",
          leftContent: "",
          data: [
            {
              chartId: "013",
              chartType: "Pie2Chart",
              chartTitle: "Personal Assets Allocation",
              chartData: updatedPersonalAssets.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Valuation", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "014",
              chartType: "Bar",
              chartTitle: "Personal Assets Summary Performance",
              chartData: updatedPersonalAssets.chartData,
              labelKey: "asset_type",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Valuation", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.personalAssets,
          tableData: updatedPersonalAssets.investments,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(
                updatedPersonalAssets.totals?.total_invested
              ),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                updatedPersonalAssets.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                updatedPersonalAssets.totals?.total_inv_aft_Debt
              ),
            },
          ],
          imgSrc: null,
          pageNumber: 6,
        },
        {
          title: "Operating Bussiness",
          leftContent: "",
          data: [
            {
              chartId: "015",
              chartType: "Pie2Chart",
              chartTitle: "Operating Bussiness Allocation",
              chartData: operatingBusiness.chartData,
              labelKey: "operating_bussiness",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Valuation", "Total Purchase Price"],
              overviewPage: true,
              chartImgSrc: "",
            },
            {
              chartId: "016",
              chartType: "Bar",
              chartTitle: "Operating Bussiness Summary Performance",
              chartData: operatingBusiness.chartData,
              labelKey: "operating_bussiness",
              pieChartType: "",
              seriesKey: ["total_inv_aft_Debt", "total_invested"],
              seriesName: ["Current Value", "Total Invested"],
              overviewPage: true,
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.operatingBusiness,
          tableData: transformedOperatingBusinessData,
          headerObjValue: [
            {
              label: "Total Investments ($)",
              value: formatCurrency(operatingBusiness.totals?.total_invested),
            },
            {
              label: "Total Current Value ($)",
              value: formatCurrency(
                operatingBusiness.totals?.total_current_value
              ),
            },
            {
              label: "Total Investment Value after Debt ($)",
              value: formatCurrency(
                operatingBusiness.totals?.total_inv_aft_Debt
              ),
            },
          ],
          imgSrc: null,
          pageNumber: 7,
        },
        {
          title: "Estate Planning",
          leftContent: "",
          data: [
            {
              chartId: "017",
              chartType: "Pie",
              chartTitle: "Portfolio Allocation Within the Estate",
              chartData: estatePlannigChartData?.current_value_with_tax,
              labelKey: "",
              pieChartType: "current_value",
              chartImgSrc: "",
            },
            {
              chartId: "018",
              chartType: "Pie",
              chartTitle: "Portfolio Allocation Outside The Estate",
              chartData: estatePlannigChartData?.current_value_without_tax,
              labelKey: "",
              pieChartType: "current_value",
              chartImgSrc: "",
            },
            {
              chartId: "019",
              chartType: "Pie",
              chartTitle: "Projected Annual Income Within the Estate",
              chartData: estatePlannigChartData?.inv_proj_income_with_tax,
              labelKey: "",
              pieChartType: "project_income",
              chartImgSrc: "",
            },
            {
              chartId: "020",
              chartType: "Pie",
              chartTitle: "Projected Annual Income Outside The Estate",
              chartData: estatePlannigChartData?.inv_proj_income_without_tax,
              labelKey: "",
              pieChartType: "project_income",
              chartImgSrc: "",
            },
          ],
          tableColumns: columnConfigs.EstatePlanning,
          tableData: UpdatedEstatePlanning.data,
          imgSrc: null,
          pageNumber: 8,
        },
        {
          title: "Company Investing",
          leftContent: "",
          data: [],
          tableColumns: columnConfigs.CompanyInvesting,
          tableData: CompanyInvesting.data,
          imgSrc: null,
          pageNumber: 9,
        },
      ];
      setPageConfigurations([...pageConfigurations, ...newPageConfigurations]);
      setProgress(10);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsBlurred(false);
    }
    // finally {
    //     setLoading(false);
    // }
  };

  const formatDate = (dateString, type) => {
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;

    if (type === "date") {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    }

    return dateString;
  };
  
  const handleToGenerateSummaryReport = () => {
    setShowModal(true);
  };

  const handleCancelPdfGeneration = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <button
          style={{ width: "100%", fontSize: "20px", marginLeft: "14px" }}
          type="button"
          onClick={handleToGenerateSummaryReport}
          className="btn btn-lg btn-primary"
        >
          <span>
            <i className="zmdi zmdi-download"></i>
          </span>
          <span style={{ marginLeft: "10px" }}>
            {isGenerating ? "Generating PDF..." : "Generate Summary Report"}
          </span>
        </button>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          {pageConfigurations.map((config, index) => (
            <div key={index}>
              {config.data?.map((chart, idx) => (
                <div
                  key={idx}
                  ref={(el) =>
                    (chartRefs.current[`${config.title}-${idx}`] = el)
                  }
                >
                  {chart.chartType === "Pie" ? (
                    <PieChart
                      header={chart.chartTitle}
                      data={chart.chartData}
                      labelKey={chart.labelKey}
                      pieChartType={chart.pieChartType}
                    />
                  ) : chart.chartType === "Pie2Chart" ? (
                    <PieChartV2
                      header={chart.chartTitle}
                      data={chart.chartData}
                      labelKey={chart.labelKey}
                      seriesKey={chart.seriesKey}
                      seriesName={chart.seriesName}
                      type={chart.chartType}
                      overviewPage={chart.overviewPage}
                    />
                  ) : (
                    <BarChart
                      header={chart.chartTitle}
                      data={chart.chartData}
                      labelKey={chart.labelKey}
                      seriesKey={chart.seriesKey}
                      seriesName={chart.seriesName}
                      type={chart.chartType}
                      overviewPage={chart.overviewPage}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Modal show={showModal} size="lg" centered>
        {!isBlurred && (
          <Modal.Header>
            <Modal.Title>Generate Summary Report</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="text-center p-4">
          {!isBlurred ? (
            <h6>
              This <strong>Summary Report</strong> generation will take
              <strong> 1 to 2 minutes</strong>. Would you like to continue?
            </h6>
          ) : (
            <>
              <h6 className="text-danger fw-bold">
                Don't Close the Tab Until the Process Ends!
              </h6>
              <div className="d-flex flex-column align-items-center">
                <ProgressBar
                  now={progress}
                  label={`${progress}%`}
                  style={{
                    width: "80%",
                    height: "20px",
                    backgroundColor: "#e9ecef",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  animated
                  variant={
                    progress < 30
                      ? "danger"
                      : progress < 60
                      ? "warning"
                      : "success"
                  }
                />
                <p className="mt-3 text-muted">
                  Generating PDF... Please wait.
                </p>
              </div>
            </>
          )}
        </Modal.Body>
        {!isBlurred && (
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="secondary"
              onClick={handleCancelPdfGeneration}
              className="fw-bold px-4"
            >
              Cancel
            </Button>
            <Button className="fw-bold px-4" onClick={generatePDFReport}>
              Continue
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Pdf_ReportGenerator;
