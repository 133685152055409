
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoader } from '../../Context/PageLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { get, put, remove } from '../../api'
import { GET_USER_BY_ID, GET_PERMISSIONS_LIST, UPDATE_USER_PERMISSION, GET_USER_PERMISSION, SEND_INVITATION, UPDATE_USER_STATUS, IMPERSONATE_USER, DELETE_USER } from '../../api/endpoints'
import { checkPermission, concatUrlWithId } from '../../utils/commonUtils';
import UserModelForm from '../userManagement/UserModelForm'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { UserPermissionStore } from '../../Store/UserPermission';
import UserPermissionTab from './UserPermissionTab';
import UserListComponent from './UserListComponent';
import { confirmAlert } from 'react-confirm-alert';

function UserInfo() {
    const { userID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const history = useNavigate();
    const { loading, setLoading } = useContext(PageLoader);
    const [permissionList, setPermissionList] = useState([]);
    const [flattenPermissionList, setFlattenPermissionList] = useState([]);

    const [userSelectedPermissionIds, setUserSelectedPermissionIds] = useState([]);
    const [permissionBox, setPermissionBox] = useState(false)
    const [selectedTab, setSelectedTab] = useState('investment')
    const [showSelectAll, setShowSelectAll] = useState(false)

    const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    const [showPermission, setShowPermission] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [userSelectedPermission, userSelectedPermissionList] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();


    /* Upload Modal  */
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');

    const [editId, setEditId] = useState('add');
    const handleClose = () => {
        fetchUserInfo();
        setShow(false);
    }
    const handleShow = (type) => {

        setShow(true);
        setMode(type)
    }
    const handleAdd = () => {
        handleShow();
    }

    const handleEdit = (id) => {
        handleShow('edit');
        setEditId(id);
    }

    const onUploadSuccess = () => {
        handleClose()
        fetchUserInfo();
    }

    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        user_name: '',
        email: '',
        mobile_no: '',
        user_image: '',
        address: ''
    });

    const notify = (message, type) => toast(message, { type });
    const fetchUserInfo = async () => {
        try {
            const response = await get(concatUrlWithId(GET_USER_BY_ID, userID));
            setLoading(true);
            if (response) {
                const fullNameParts = response.full_name.split(' - ');
                const firstName = fullNameParts[0];
                const lastName = fullNameParts[1];
                let values = {
                    first_name: firstName,
                    last_name: lastName,
                    user_name: response.username,
                    email: response.email,
                    mobile_no: response.phone,
                    user_image: response.profileImagePath,
                    address: response.address,
                    status: response.status,
                    full_name: `${firstName} ${lastName}`,
                    manager: response.manager,
                    owner_by: response?.managerDetails?.username
                };
                setInitialValues(values);
            }

        } catch (error) {
            console.log('Error while fetching user information:', error);
            notify(error?.response?.data?.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission);
        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
            fetchUserInfo();
            getPermissions();
            fetchUserPermission('ALL', userID);
        } else {
            setLoading(false);
        }
    };

    const getPermissions = async () => {
        try {
            setLoading(true);
            let permissionList = await get(GET_PERMISSIONS_LIST);
            setPermissionList(permissionList);
            const transformedPermissions = permissionList.map(group => group.permissions);
            const flattenedPermissions = transformedPermissions.flat();
            setFlattenPermissionList(flattenedPermissions);
        } catch (error) {
            console.log('Error while fetching user permission:', error);
            notify(error?.response?.data?.message, 'error');
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(false)
        authCheckAndFetch()
    }, []);

    function checkStatus(status) {
        if (!isNaN(status)) {
            if (status == 2) {
                return <span className="badge text-bg-danger">Deactivate</span>;
            } else if (status == 1) {
                return <span className="badge text-bg-primary">Active</span>;
            } else if (status == 0) {
                return <span className="badge bg-info ">Invited</span>;
            } else {
                return <span className="badge text-bg-primary">--</span>;
            }
        } else {
            return <span>_ _</span>;
        }
    };

    // const handleBackClick = () => {
    //     history('/users');
    // };
    const handleBackClick = () => {
        const previous = document.referrer;
        const currentDomain = window.location.origin;
        if ((previous.includes(currentDomain) || previous === '#') && window.history.length > 1) {
            window.history.back();
        } else {
            window.location.href = '/users';
        }
    };

    function hasRelativePermission(usrPerm, relativePermission) {

        for (let i = 0; i < flattenPermissionList.length; i++) {
            if (flattenPermissionList[i].id === usrPerm && flattenPermissionList[i].related_permissionID === relativePermission) {
                return true; // Return true if a matching main with the specified sub is found
            }
        }
        return false; // Return false if no matching main with the specified sub is found
    }

    function testRelativePermission(usrPerm, relPerm) {
        for (let i = 0; i < usrPerm.length; i++) {
            let hasRelative = hasRelativePermission(usrPerm[i], relPerm);
            if (hasRelative) {
                return true; // Stop the loop and return true if a match is found
            }
        }
        return false; // Return false if no match is found
    }

    //fetch user Permission
    const fetchUserPermission = async (permissionType, userID) => {
        let userPermission;
        try {

            userPermission = await get(concatUrlWithId(GET_USER_PERMISSION, permissionType) + '/' + userID);

            if (userPermission) {
                // Remove duplicates
                const userPermissions = userPermission.userPermission;

                setSelectedPermissions(userPermissions)
                userSelectedPermissionList(userPermissions);
                setUserSelectedPermissionIds(userPermissions?.length ? userPermissions.map(p => p.PermissionId) : []);
                setShowPermission(true);

                // Extract related_permissionID from userPermission and disable corresponding checkboxes
                const disabledIds = userPermissions.map(permission => permission['permission.related_permissionID']).filter(id => id !== null);
                setDisabledCheckboxes(disabledIds);
            }
        } catch (error) {
            console.log('Error while fetching users: ', error);
        }
    };

    const userUpdatePermission = async (values) => {
        try {
            let url;

            url = UPDATE_USER_PERMISSION;
            let permissionType = 'ALL';
            const bodyPayload = {
                UserId: userID,
                PermissionIds: userSelectedPermissionIds,
                Permission_type: permissionType
            };
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Permission shared successfully with ${initialValues.user_name}`, 'success')
            } else {
                notify('Permission Updated failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.message, 'error');
            console.error('API Error:', error);
        }
    };

    const handlePermissionChange = (permissionId, checked, userPermission) => {

        const matchID = flattenPermissionList.find(permission => permission.id === permissionId);
        let relativePermissionsId = matchID.related_permissionID;
        if (checked) {
            setSelectedPermissions(prev => [...prev, permissionId]);
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds, permissionId] : [permissionId];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (!tempUsrSelectedPermission.includes(relativePermissionsId) && !selectedPermissions.includes(relativePermissionsId) && relativePermissionsId !== null) {
                tempUsrSelectedPermission.push(relativePermissionsId);
            }

            if (relativePermissionsId !== null && !tempDisabledPermission.includes(relativePermissionsId)) tempDisabledPermission.push(relativePermissionsId);

            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)

        } else {
            setSelectedPermissions(prev => prev.filter(id => id !== permissionId));
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds].filter(id => id !== permissionId) : [];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (tempUsrSelectedPermission.length == 0 || tempUsrSelectedPermission.length == 1) {
                if (tempUsrSelectedPermission.length == 0 && (relativePermissionsId && !selectedPermissions.includes(relativePermissionsId))) {
                    tempUsrSelectedPermission = [];
                }
                tempDisabledPermission = [];
            } else {
                let checkSameRelativeIdExistInOP = testRelativePermission(tempUsrSelectedPermission, relativePermissionsId);
                if (!checkSameRelativeIdExistInOP) {
                    tempDisabledPermission = tempDisabledPermission.filter(id => id !== relativePermissionsId)
                }
            }
            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)
        }
    };

    const handleTabChange = (tabName, event) => {
        event.preventDefault();
        setSelectedTab(tabName);
    }

    const filterPermssionListForOthers = (type) => {

        const NeededTypes = ["Archived", "EstatePlanning", "General", "OperatingBusiness",];
        const filteredResult = []
        if (type == 'permission') {
            permissionList.forEach((group) => {
                if (NeededTypes.includes(group.group.permission_type)) {
                    filteredResult.push(group);
                }
            })
        }
        if (type == 'flattenPermissionList') {
            flattenPermissionList.forEach((group) => {
                if (NeededTypes.includes(group.permission_type)) {
                    filteredResult.push(group)
                }
            })
        }

        return filteredResult;
    }

    function userActionStatus(status) {
        if (!isNaN(status)) {
            if (status == 2) {
                return "Active";
            } else if (status == 1) {
                return "Deactivate";
            } else if (status == 0) {
                return "Cancel Invite";
            } else {
                return "Resend Invitation";
            }
        } else {
            return "";
        }
    };

    const STATUS_CONFIG = {
        'Resend Invitation': { Message: 'Are you sure you want to invite this user?', Status: 0 },
        'Cancel Invite': { Message: 'Are you sure you want to remove the invite for this user?', Status: 3 },
        'Active': { Message: 'Are you sure you want to activate this user?', Status: 1 },
        'Deactivate': { Message: 'Are you sure you want to deactivate this user?', Status: 2 },

    };

    const onUserStatusConfrim = async (status) => {
        try {
            setLoading(true);
            console.log("USer invite :::: ", status)
            const userStatus = status === 0 ? await get(concatUrlWithId(SEND_INVITATION, userID)) :
                status === 3
                    ? await remove(concatUrlWithId(DELETE_USER, userID))
                    : await put(concatUrlWithId(UPDATE_USER_STATUS, userID), { status });

            setLoading(false);
            let successMessage = '';
            if (status == 0) {
                successMessage = 'The user has been successfully invited.';
            } else {
                successMessage = userStatus.message
            }
            notify(`${initialValues.user_name} ${successMessage}`, 'success');
            setInitialValues({ ...initialValues, status: status });
            if (status === 3) {
                // window.location.href = '/user';
                history('/users')
            }
        } catch (error) {
            console.log('Error in the onUserStatus confirm ::: ', error);
        }
    }

    const showUserStatusAlert = (message, status) => {
        confirmAlert({
            title: 'User Status',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => onUserStatusConfrim(status)
                },
                { label: 'No' }
            ]
        });
    };

    const handleUserStatusChange = (status) => {
        const alertMessage = STATUS_CONFIG[status]?.Message || 'Invalid user status';
        showUserStatusAlert(alertMessage, STATUS_CONFIG[status]?.Status);
    };

    const switchToImpersonation = async () => {
        try {
            const impersonateUser = await get(concatUrlWithId(IMPERSONATE_USER, userID));

            const authToken = {
                token: impersonateUser?.data?.token,
                refreshToken: impersonateUser?.data?.refreshToken,
            };

            localStorage.setItem('authToken', JSON.stringify(authToken));
            localStorage.setItem('profileImageUrl', JSON.stringify(impersonateUser?.data?.data?.valid_user?.profileImagePath || ''));
            localStorage.setItem('user-permission', '');
            localStorage.setItem('impersonateMode', 'Active');

            // Reload the page to reflect impersonation changes
            window.location.reload();
        } catch (error) {
            console.error('Error in switchToImpersonation:', error);
        }
    };

    const handleImpersonateUser = () => {
        confirmAlert({
            title: 'User Status',
            message: 'You are about to log in as this user. Do you want to proceed?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: switchToImpersonation
                },
                { label: 'No' }
            ]
        });
    }


    useEffect(() => {
        // window.scrollTo(0, 0);
    }, [selectedTab]);


    return (
        <>
            <div className="main-content introduction-farm">
                <div className="content-wraper-area">
                    <div className="dashboard-area">
                        <div className="container-fluid">
                            <div className="row g-4">
                                <div className="container-fluid" style={{ marginTop: '20px' }}>
                                    <div className="col-12" >
                                        <div className="card">
                                            <div className="card-body card-breadcrumb">
                                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                                    <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                                        Back</a>
                                                    <div className="page-title-right">
                                                        <ol className="breadcrumb m-0">
                                                            <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}> Users
                                                            </a></li>
                                                            <li className="breadcrumb-item active" >User Info</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header-cu">
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <h6 className="mb-0">Basic information</h6>
                                                </div>
                                                <div className='col-6'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                            {(checkPermission(userPermissions, 'isAdmin') && (initialValues.status == 1)) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Login as this user</Tooltip>} >
                                                                <a className='user-impersonation-button' onClick={() => handleImpersonateUser()}>Login as this user</a>
                                                            </OverlayTrigger>}
                                                            {(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>{userActionStatus(initialValues.status)}</Tooltip>} >
                                                                <button className='user-status-button' onClick={() => handleUserStatusChange(userActionStatus(initialValues.status))} >{userActionStatus(initialValues.status)}</button>
                                                            </OverlayTrigger>}

                                                            {<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit user</Tooltip>} >
                                                                <i className="bx bxs-edit btn btn-circle" onClick={() => handleEdit(userID)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row border-bottom'>
                                                <div className={initialValues.user_name && initialValues.user_name.length > 10 ? "col-lg-6" : "col-lg-3"}>
                                                    <form>
                                                        <div className="d-flex align-items-center pb-4 mb-4">
                                                            <div className="account-img">
                                                                <img className="profile-image" src={initialValues.user_image || "/img/profile-image.png"} alt="" />
                                                            </div>
                                                            <div>
                                                                <h5>{initialValues.user_name}</h5>
                                                                <div className="mt-3">
                                                                    <span>{checkStatus(initialValues.status)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className={"col-lg-9"}>
                                                    <div className="pb-4">
                                                        <div className="personal-info-area">
                                                            <div className="row chat-msg">
                                                                <div className="col-lg-6">
                                                                    <p className="d-flex flex-wrap align-items-center">
                                                                        <div className="me-2">Name:</div> <span className="text-muted">{initialValues.full_name}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <p className="d-flex flex-wrap align-items-center">
                                                                        <div className="me-2">Email:</div> <span className="text-muted">{initialValues.email}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <p className="d-flex flex-wrap align-items-center">
                                                                        <div className="me-2">Phone:</div> <span className="text-muted">{initialValues.mobile_no}</span>
                                                                    </p>
                                                                </div>
                                                                {
                                                                    checkPermission(userPermissions, 'isAdmin') && <div className="col-lg-6">
                                                                        <p className="d-flex flex-wrap align-items-center">
                                                                            <div className="me-2">Role:</div> <span className="text-muted">{initialValues.manager ? 'User' : 'Manager'}</span>
                                                                        </p>
                                                                    </div>}
                                                                {(checkPermission(userPermissions, 'isAdmin') && initialValues?.owner_by) && (
                                                                    <div className="col-lg-6">
                                                                        <p className="d-flex flex-wrap align-items-center">
                                                                            <div className="me-2">Manager:</div> <span className="text-muted">{initialValues.owner_by}</span>
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <div className="col-lg-6">
                                                                    <p className="d-flex flex-wrap align-items-center">
                                                                        <div className="me-2">Address :</div> <span className="text-muted">{initialValues.address}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !initialValues.manager &&
                                    <UserListComponent managerId={userID} status={initialValues.status} />
                                }
                                {
                                    <UserPermissionTab
                                        initialValues={initialValues} userID={userID} selectedTab={selectedTab}
                                        permissionList={filterPermssionListForOthers('permission')} userPermissions={userPermissions}
                                        userUpdatePermission={userUpdatePermission} disabledCheckbox={disabledCheckboxes}
                                        userSelectedPermissionIds={userSelectedPermissionIds} handleTabChange={handleTabChange}
                                        handleBackClick={handleBackClick} handlePermissionChange={handlePermissionChange} flattenPermissionList={filterPermssionListForOthers('flattenPermissionList')}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UserModelForm show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} investmentId={userID} headerName={'User'} />
        </>
    );
}

export default UserInfo;