import { useState, useEffect } from 'react';
import { FormField, SelectFieldV2, ViewDiv } from './Forms/FormFields';
import { ActionIcon, concatUrlWithId, formatCurrency } from '../utils/commonUtils';
import { useParams } from 'react-router-dom';
import { DELETE_CASH_ACCOUNTS, DELETE_INVESTMENT_BY_ID } from '../api/endpoints';
import { remove } from '../api';
function CashAccount({ viewMode, cashOptions, handleCashAccountChange, selectedAccount, handleCashRemove, hasEdit }) {
    const { investmentID } = useParams();
    const [cashAccountList, setCashAccountList] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        const filteredCashOptions = cashOptions.filter(
            option => !selectedAccount.some(account => account.value === option.value)
        );

        setSelectedAccounts(selectedAccount)
        setCashAccountList(filteredCashOptions)
        // setSelectedAccounts([
        //     {
        //         "value": "69589fcb-4502-4376-9d8f-3e11c3be73b0",
        //         "label": "Akash Manager Id ",
        //         "current_value": "1000.00"
        //     },
        //     {
        //         "value": "8c6e66bd-0410-4201-a3d8-5e70a94a996d",
        //         "label": "Gold investment ",
        //         "current_value": "1500.00"
        //     }
        // ])
    }, [cashOptions, selectedAccount]);



    const documentTypes = [
        { label: 'Invesment', value: 'Invesment' },
        { label: 'Value', value: 'Value' },
    ];

    const handleUpdateValue = () => {
        if (selectedValue) {
            let selectedIndex = cashAccountList.findIndex(account => account.value === selectedValue);
            let tempCashAccount = cashAccountList;
            let selectedItem = cashOptions.filter(account => account.value === selectedValue);
            let tempValue = [...selectedAccounts, ...selectedItem]
            tempCashAccount.splice(selectedIndex, 1)
            const totalValue = selectedItem.reduce((acc, choice) => {
                const currentValue = parseFloat(choice.current_value) || 0; // Safely parse current_value
                return acc + currentValue;
            }, 0);

            setSelectedAccounts(tempValue);
            setCashAccountList(tempCashAccount);
            setSelectedValue('');
            handleCashAccountChange(tempValue, selectedValue, totalValue)
        } else {
            console.log("No Value was selected ::::::::::::: ");
        }
    };
    const handleRemove = (removeIndex, value) => {
        let tempSelectedAccount = selectedAccounts;
        tempSelectedAccount.splice(removeIndex, 1);
        setCashAccountList([...cashAccountList, value]);
        setSelectedAccounts(tempSelectedAccount);
        handleCashRemove(value);
    };

    const viewModeRemove = async (removeIndex, value) => {
        const response = await remove(concatUrlWithId(DELETE_CASH_ACCOUNTS, investmentID) + '/' + value.value);
        handleCashRemove(value)
        let tempSelectedAccount = selectedAccounts;
        tempSelectedAccount.splice(removeIndex, 1);
        setCashAccountList([...cashAccountList, value]);
        setSelectedAccounts(tempSelectedAccount);
        // DELETE_CASH_ACCOUNTS

    }

    return (
        <> 
        <div className='ml-2'>
            {!viewMode && (
                <div className="row">
                    <div className="col-10">
                        <SelectFieldV2
                            viewMode={viewMode}
                            fieldName="cash_accounts"
                            label="Choose Cash Accounts"
                            value={selectedValue}
                            options={cashAccountList}
                            isRequired={false}
                            setFieldValue={(field, id, label, value) => {
                                setSelectedValue(id);
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
                            <button
                                type="button"
                                className="btn btn-sm btn-secondary"
                                onClick={() => handleUpdateValue()}
                            >
                                <i className="ti-plus me-1"></i> Add
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {viewMode && (
                <div className="col-12" style={{marginLeft:"4px"}}>
                    <label className="inv-label" htmlFor={`formrow-input`}>
                        Cash Accounts
                    </label>
                </div>
            )}

            {selectedAccounts?.length ? (
                <div
                    className="row"
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '4px',
                        // margin: '0',
                        marginBottom: '15px',
                        maxHeight: selectedAccounts.length > 3 ? '200px' : 'auto', // Set max height if more than 3 items
                        overflowY: selectedAccounts.length > 3 ? 'auto' : 'visible', // Enable scrolling
                    }}
                >
                    {/* Header */}
                    {/* <div
                        className="row"
                        style={{
                            fontWeight: 'bold',
                            borderBottom: '2px solid #ddd',
                            padding: '8px 0',
                            margin: '0',
                        }}
                    >
                        <div className="col-5">Cash Accounts</div>
                        <div className="col-5">Value</div>
                        <div className="col-2" style={{ textAlign: 'right' }}>
                            Action
                        </div>
                    </div> */}

                    {/* Rows */}
                    {selectedAccounts.map((account, index) => (
                        <div
                            className="row"
                            key={index}
                            style={{
                                padding: '4px 0',
                                margin: '0',

                            }}
                        >
                            <div className="col-5">
                                <a
                                    href={`${window.location.origin}/view-liquid-asset/${account.value}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: '#007bff', textDecoration: 'none' }}
                                >
                                    {account.label}
                                </a>
                            </div>
                            <div className="col-5" id="inv-value">
                               {formatCurrency(account.current_value)}
                                {/* {account.current_value} */}
                            </div>
                            {hasEdit && (<div className="col-2" style={{ textAlign: 'right' }}>
                                {!viewMode && (<ActionIcon
                                    iconClass="bx bx-trash"
                                    tooltip="Delete"
                                    onClick={() => {
                                        handleRemove(index, account);
                                    }}
                                    backgroundColor="#dd4a57"
                                    cashAccount={true}
                                />)}
                                {viewMode && hasEdit && (<ActionIcon
                                    iconClass="bx bx-trash"
                                    tooltip="Delete"
                                    onClick={() => {
                                        viewModeRemove(index, account);
                                    }}
                                    backgroundColor="#dd4a57"
                                    cashAccount={true}
                                />)}
                            </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                ''
            )}
            </div>
        </>
        

    );
}

export default CashAccount;
