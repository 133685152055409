import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';

const BarChart= ({ header, data, labelKey, seriesKey, seriesName, filteredChartValue = '' }) => {
    const [chartType, setChartType] = useState('pie');
    const headerName = header || 'chart';
    const chartRef = useRef(null);

    const handleChange = (event) => {
        const selectedChartType = event.target.value;
        setChartType(selectedChartType); // Update chart view state
    };

    const [barChart, setBarChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                width: '100%', // Change width to 100% for responsiveness
                height: 'auto',
                toolbar: {   
                    show: true,
                    tools: {
                        download: false,  // export the chart options disable
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
                colors: ["#fff"],
            },
            xaxis: {
                categories: [],
            },
            legend: {
                position: "bottom", // Change legend position for mobile
                markers: {
                    width: 10,
                    height: 10,
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(value);
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                        }).format(value);
                    },
                },
            },
            noData: {
                text: 'No data available',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: "30px",
                    color: "#7f838b",
                    opacity: "0.7",
                },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 400,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
                {
                    breakpoint: 800,
                    options: {
                        chart: {
                            height: 300,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                            },
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
                {
                    breakpoint: 600, // Add a breakpoint for mobile devices
                    options: {
                        chart: {
                            height: 350, // Adjust height for mobile view
                            width: 250,
                        },
                        legend: {
                            position: "bottom",
                            markers: {
                                width: 8, // Adjust marker size for mobile view
                                height: 8,
                            },
                        },
                    },
                },
                {
                    breakpoint: 400, // Add a breakpoint for mobile devices
                    options: {
                        chart: {
                            height: 350, // Adjust height for mobile view
                            width: 250,
                        },
                        legend: {
                            position: "bottom",
                            markers: {
                                width: 8, // Adjust marker size for mobile view
                                height: 8,
                            },
                        },
                    },
                },
            ],
        },
    });


    useEffect(() => {
        const transformedData = data.reduce((result, item) => {
            let assetType = labelKey == "operating_bussiness" ? 'Operating Business' : item[labelKey];
            const currentValue = item[seriesKey[0]] ? parseFloat(item[seriesKey[0]]) : 0.00;
            const investedValue = item[seriesKey[1]] ? parseFloat(item[seriesKey[1]]): 0.00;
            // Check if the assetType already exists in the result
         
            if (assetType in result) {
                // If the assetType already exists, update the currentValue and investedValue
                result[assetType].currentValue += currentValue;
                result[assetType].investedValue += investedValue;
            } else {
                // If the assetType doesn't exist, add a new entry
                result[assetType] = { currentValue };
                result[assetType].investedValue = investedValue;
            }
            if(assetType == 'Operating Business' ){
            }
            return result;
        }, {});

        const totalSum = Object.values(transformedData).reduce((acc, asset) => acc + asset.currentValue, 0);

        // Calculate percentages and create labeled data
        const labeledData = {};
        Object.keys(transformedData).forEach((key) => {
            if (chartType !== "bar") {
                const percentage = ((transformedData[key].currentValue / totalSum) * 100).toFixed(1);
                labeledData[`${key} (${percentage}%)`] = transformedData[key].currentValue;
            }
        });

        // Chart Data by key and values
        const investedAmt = Object.values(transformedData).map(item => parseFloat(item.investedValue).toFixed(2));
        const currentAmt = Object.values(transformedData).map(item => parseFloat(item.currentValue).toFixed(2));
        setBarChart((prevBarChart) => ({
            ...prevBarChart,
            series: [
                { name: seriesName[1], data: investedAmt },
                { name: seriesName[0], data: currentAmt }
            ],
            options: {
                ...prevBarChart.options,
                xaxis: {
                    categories: Object.keys(transformedData)
                }
            }
        }));

        let tempLabels = Object.keys(labeledData);


        if(filteredChartValue){
            const selectChartIndex = tempLabels.findIndex(item => item.includes(filteredChartValue));
            if (data.length) {
                setTimeout(() => {
                  if(chartRef.current?.chart){
                      chartRef.current.chart.toggleDataPointSelection(selectChartIndex);
                  }
              }, 100);
            }
        }
     
    }, [data, filteredChartValue]);

    return (
        <div className="row ">
            <div className="col-12 mb-20 mb-2">
                <div className="card">
                    <div className="card-body">
                        <div className=" single-widget d-flex align-items-center justify-content-between" style={{ marginBottom: '5px' }}>
                            <div className="card-title">
                                <h4>{`${headerName}`}</h4>
                            </div>
                        </div>
                        <div id="stock-overview" style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {data?.length ? <Chart
                                id="bar-chart"
                                options={barChart.options}
                                series={barChart.series}
                                type={"bar"} height={250} width={500} /> :
                                <div className="datachart-nodata">No data available</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BarChart;
