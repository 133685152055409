import React, { useState, useEffect } from 'react';
import { checkPermission } from '../../utils/commonUtils';
import InvesmentPermissionTable from './InvesmentPermissionTable';
import Datatable from '../../components/Table/Datatable';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UPDATE_USER_PERMISSION } from '../../api/endpoints'
import { get, put } from '../../api'



export default function UserPermissionTab({ initialValues, userID, flattenPermissionList, selectedTab, permissionList, userPermissions, userSelectedPermissionIds, disabledCheckbox, handleTabChange, handleBackClick, handlePermissionChange }) {
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState([]);
    // const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    var localSelectedPermissions = [...userSelectedPermissionIds]; // Initialize with userSelectedPermissionIds
    var disabledCheckboxes = [...disabledCheckbox]
    const notify = (message, type) => toast(message, { type });

    useEffect(() => {
        dataTable();
    }, [userSelectedPermissionIds, disabledCheckbox]);

    const dataTable = () => {
        const generatedColumns = [];
        const tableData = [];
        const columnNames = new Set();

        // Determine header checkbox state
        const getHeaderCheckboxState = (columnName) => {
            const columnPermissions = flattenPermissionList.filter(
                (permission) => permission.permission_name === columnName
            );

            const totalPermissions = columnPermissions.length;
            const selectedPermissions = columnPermissions.filter((permission) =>
                localSelectedPermissions.includes(permission.id)
            ).length;

            if (selectedPermissions === totalPermissions) {
                return "checked";
            }
            if (selectedPermissions > 0) {
                return "indeterminate";
            }
            return "unchecked";
        };

        permissionList.forEach((group) => {
            const { permissions, permission_type: groupName } = group;
            // Create a row for this group with the group name
            const rowData = { Name: group.group.permission_type };
            // Process permissions for this group
            permissions.forEach((permission) => {
                const { id, permission_name } = permission;

                // Add a column for this permission if not already added
                if (!columnNames.has(permission_name)) {
                    columnNames.add(permission_name);

                    generatedColumns.push({
                        dataField: permission_name,
                        text: (
                            <div style={{ display: 'flex', minWidth: '150px', maxWidth: '200px', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                    type="checkbox"
                                    ref={(checkbox) => {
                                        if (checkbox) {
                                            const state = getHeaderCheckboxState(permission_name);
                                            checkbox.checked = state === "checked";
                                            checkbox.indeterminate = state === "indeterminate";
                                        }
                                    }}
                                    disabled={flattenPermissionList.filter((permission) => permission.permission_name === permission_name).every((permission) => disabledCheckboxes.includes(permission.id))}
                                    onChange={(e) => onHeaderCheckboxChange(permission_name, e.target.checked)}
                                    style={{ width: '17px', height: '17px', marginRight: '10px' }}
                                />
                                {permission_name}
                            </div>
                        ),
                        sort: false,
                        formatter: (cell, row) => {
                            if (cell && cell.permission_name) {
                                const { id, permission_name } = cell;

                                // Check if the permission is selected
                                const hasPermission = localSelectedPermissions.includes(id);

                                return (
                                    <input
                                        type="checkbox"
                                        name={permission_name}
                                        value={id}
                                        checked={hasPermission || disabledCheckboxes.includes(id)}
                                        disabled={disabledCheckboxes.includes(id)}
                                        onChange={() => onCheckboxChange(id)}
                                        style={{ width: '17px', height: '17px', display: 'block', margin: '0 auto' }}
                                    />
                                );
                            }
                            return cell; // Return the cell value as-is if it doesn't match the expected structure
                        },
                    });
                }
                // Add the permission id to the rowData for this specific permission
                rowData[permission_name] = { id, permission_name };
                rowData['userPermissionsData'] = userSelectedPermissionIds;

            });

            tableData.push(rowData);
            // }
        });

        generatedColumns.unshift({
            dataField: 'Name',
            text: 'Name',
            sort: true,
        });

        setColumns(generatedColumns);
        setFilteredData(tableData);
        setLoading(false);
    };


    function hasRelativePermission(usrPerm, relativePermission) {
        return flattenPermissionList.some(permission =>
            permission.id === usrPerm && permission.related_permissionID === relativePermission
        );
    }

    function testRelativePermission(usrPerm, relPerm) {
        return usrPerm.some(permission => hasRelativePermission(permission, relPerm));
    }

    const onHeaderCheckboxChange = (columnName, isChecked) => {
        setFilteredData((prevFilter) => {
            // Find permissions for the given column name

            const columnPermissions = flattenPermissionList.filter(
                (permission) => permission.permission_name === columnName
            );
            const relatedPermissionIds = columnPermissions
                .map((permission) => permission.related_permissionID)

            let tempDisabledPermissions = [...disabledCheckboxes];
            let tempSelectedPermissions = [...localSelectedPermissions];

            if (isChecked) {


                // Add selected permissions and their related permissions
                columnPermissions.forEach((permission) => {
                    if (!tempSelectedPermissions.includes(permission.id)) {
                        tempSelectedPermissions.push(permission.id);
                    }
                    if (permission.related_permissionID && !tempSelectedPermissions.includes(permission.related_permissionID)) {
                        tempSelectedPermissions.push(permission.related_permissionID);
                    }
                    if (permission.related_permissionID && !tempDisabledPermissions.includes(permission.related_permissionID)) {
                        tempDisabledPermissions.push(permission.related_permissionID);
                    }
                });
            } else {
                // Remove unselected permissions and handle their related permissions
                columnPermissions.forEach((permission) => {
                    tempSelectedPermissions = tempSelectedPermissions.filter(
                        (permId) => permId !== permission.id
                    );
                    if (
                        permission.related_permissionID &&
                        !tempSelectedPermissions.includes(permission.related_permissionID)
                    ) {
                        tempSelectedPermissions = tempSelectedPermissions.filter(
                            (permId) => permId !== permission.related_permissionID
                        );
                        tempDisabledPermissions = tempDisabledPermissions.filter(
                            (permId) => permId !== permission.related_permissionID
                        );
                    }
                });
            }

            // Clean up disabled permissions to ensure correctness
            tempDisabledPermissions = tempDisabledPermissions.filter((permissionId) =>
                flattenPermissionList.some(
                    (permission) =>
                        permission.related_permissionID === permissionId &&
                        tempSelectedPermissions.includes(permission.id)
                )
            );

            // Update state variables
            localSelectedPermissions = tempSelectedPermissions;
            disabledCheckboxes = tempDisabledPermissions;
            setSelectedPermission(localSelectedPermissions);
            dataTable();


            // Update the filtered data structure
            return prevFilter.map((data) => ({
                ...data,
                userPermissionsData: [...tempSelectedPermissions],
            }));
        });
    };

    const onCheckboxChange = (id) => {
        
        setFilteredData((prevFilter) => {
            const matchID = permissionList.flatMap(group => group.permissions).find(permission => permission.id === id);
            const relativePermissionsId = matchID?.related_permissionID;
            let tempUsrSelectedPermission = [...localSelectedPermissions];
            let tempDisabledPermission = [...disabledCheckboxes];

            if (tempUsrSelectedPermission.includes(id)) {
                tempUsrSelectedPermission = tempUsrSelectedPermission.filter(permissionId => permissionId !== id);
                if (relativePermissionsId && !tempUsrSelectedPermission.includes(relativePermissionsId) && !selectedPermission.includes(relativePermissionsId)) {
                    tempUsrSelectedPermission.push(relativePermissionsId);
                }
                if (relativePermissionsId && !tempDisabledPermission.includes(relativePermissionsId)) {
                    tempDisabledPermission.push(relativePermissionsId);
                }
            } else {
                tempUsrSelectedPermission.push(id);
                if (relativePermissionsId && !tempUsrSelectedPermission.includes(relativePermissionsId)) {
                    tempUsrSelectedPermission.push(relativePermissionsId);
                }
                if (relativePermissionsId && !tempDisabledPermission.includes(relativePermissionsId)) {
                    tempDisabledPermission.push(relativePermissionsId);
                }
            }

            tempDisabledPermission = tempDisabledPermission.filter(permissionId =>
                !tempUsrSelectedPermission.includes(permissionId) || permissionList.flatMap(group => group.permissions)
                    .some(permission => permission.related_permissionID === permissionId && tempUsrSelectedPermission.includes(permission.id))
            );

            setSelectedPermission(tempUsrSelectedPermission);
            disabledCheckboxes = tempDisabledPermission;
            localSelectedPermissions = tempUsrSelectedPermission;
            dataTable();
            return prevFilter.map((data) => ({
                ...data,
                userPermissionsData: [...tempUsrSelectedPermission],
            }));
        });
    };

    const handleSave = () => {

        const formattedPermissions = selectedPermission.map((selectedId) => {
            let matchedPermissionType = 'Unknown'; // Default fallback type

            permissionList.forEach((group) => {
                const foundPermission = group.permissions.find((perm) => perm.id === selectedId);
                if (foundPermission) {
                    matchedPermissionType = group.permission_type;
                }
            });

            if (!matchedPermissionType) {
                console.warn(`Permission type not found for ID: ${selectedId}`);
            }

            return {
                PermissionId: selectedId,
                Permission_type: matchedPermissionType,
            };
        });

        userUpdatePermission(formattedPermissions);
    };

    const userUpdatePermission = async (formattedPermissions) => {
        try {
            const url = UPDATE_USER_PERMISSION;

            // Prepare payload with all selected PermissionIds
            const bodyPayload = {
                UserId: userID,
                PermissionIds: formattedPermissions.map((perm) => perm.PermissionId), // Extract PermissionId array
                Permission_type: formattedPermissions[0]?.Permission_type || 'ALL', // Fallback type
            };


            // Make API call
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Permission shared successfully with ${initialValues.user_name}`, 'success');
            } else {
                notify('Permission update failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.message || 'An error occurred', 'error');
            console.error('API Error:', error);
        }
    };

    return (
        <>
            {initialValues.manager && <div key="user-permission-tab" className='card'>
                <div className='card-body'>
                    {/* Tab buttons */}
                    <ul className="nav nav-tabs">
                        <li key={'investment-tab'} className="nav-item" onClick={(e) => handleTabChange('investment', e)}>
                            <a className={`nav-link ${selectedTab === 'investment' ? 'active' : ''}`} href="#">Investment</a>
                        </li>
                        <li key={'category-tab'} className="nav-item" onClick={(e) => handleTabChange('category', e)}>
                            <a className={`nav-link ${selectedTab === 'category' ? 'active' : ''}`} href="#">General</a>
                        </li>
                    </ul>
                </div>
                {(selectedTab === 'investment') && (
                    <div className="card-body" style={{ minHeight: '420px', overflow: 'auto' }}>
                        <InvesmentPermissionTable userId={userID} isManager={checkPermission(userPermissions, 'isManager')} />
                    </div>
                )}
                {selectedTab === 'category' && (
                    <div className="card-body" style={{ minHeight: '420px', overflow: 'auto' }}>
                        <div className="row">
                            <div className="col-6">
                                <legend className="text-bold">Permissions</legend>
                            </div>
                        </div>

                        {!loading &&  (
                            <Datatable data={filteredData} columns={columns} disableCsv={true} FreezeColumn={true} disableSearch={true} />
                        ) }

                        {loading && <div style={{ margin: '100px' }}>Loading...</div>}

                        {/* Save button below the table */}
                        <div className="text-right mt-3">
                            <button className="btn btn-primary" onClick={handleSave}>
                                Save
                            </button>
                        </div><br></br>
                    </div>
                )}
            </div>}
        </>
    );
}
