import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from '../../api';
import { GET_USER_PERSONAL_INFORMATION } from '../../api/endpoints';
import SubscriptionDetails from './subscriptionDetails';
import UserInfo from './userDetails';
function Profile({ userInfo }) {
    const [userDetails, setUserDetails] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tabFromUrl = searchParams.get("tab");
    const [selectedTab, setSelectedTab] = useState(tabFromUrl || (userInfo ? "userInfo" : "subscriptionInfo"));
    const [showPaymentTab, setShowPaymentTab] = useState(false);


    const fetchUserInfo = async () => {
      try {
        const response = await get(GET_USER_PERSONAL_INFORMATION);
        if (response) {
          
          if(response.isAdmin === true || response.manager === null){        
            setShowPaymentTab(true);
          }else{
            setShowPaymentTab(false);
            handleTabChange("userInfo", new Event("change"));
            setTimeout(() => {
              if (window && selectedTab != 'userInfo') {
                window.location.reload();
              }
            }, 300);
          }
          setUserDetails(response);
        }
      } catch (error) {
        console.log("Error while fetching user information:", error);
      }
    };
    useEffect(() => {
      fetchUserInfo();
    }, [userInfo]);


    // const handleSubscriptionStatus = (nextPaymentDate, subscribeId) => {
    //   if (!subscribeId) {
    //     setStatusMessage(
    //       `You dont have a necessary subscription plan, Please update you payment accourdingly!`
    //     );
    //     setBgColor("warning"); // Orange for near expiry
    //     setNotifySubscriptionExperied(true);
    //   } else {
    //     if (!nextPaymentDate) return;

    //     const today = new Date();
    //     const expDate = new Date(nextPaymentDate);
    //     const diffTime = expDate - today;
    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

    //     setDaysLeft(diffDays);

    //     if (diffDays > 0 && diffDays <= 7) {
    //       setStatusMessage(
    //         `Your subscription will expire in ${diffDays} days.`
    //       );
    //       setBgColor("warning"); // Orange for near expiry
    //       setNotifySubscriptionExperied(true);
    //     } else if (diffDays <= 0) {
    //       setStatusMessage("Your subscription has expired. Please subscribe.");
    //       setBgColor("danger"); // Red for expired
    //       setNotifySubscriptionExperied(true);
    //     } else {
    //       setNotifySubscriptionExperied(false);
    //     }
    //   }
    // };

    const handleTabChange = (tabName, event) => {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?tab=" +
        encodeURIComponent(tabName);
      window.history.pushState({ path: newurl }, "", newurl);
      // }
      event.preventDefault();
      setSelectedTab(tabName);
    };
    const notify = (message, type) => toast(message, { type });
    return (
        <div className="main-content introduction-farm">
            {/* {notifySubscriptionExperied && (
        <div className={`alert alert-${bgColor} text-center p-3 fw-bold`}>
          {statusMessage}
        </div>
      )} */}
          <div className="content-wraper-area">
            <div className="dashboard-area">
              <div className="container-fluid">
                <div className='card'>
                  <div className='card-body'>
                    {/* Tab buttons */}
                    <ul className="nav nav-tabs no-print">
                      <li className="nav-item" onClick={(e) => handleTabChange('userInfo', e)}>
                        <a className={`nav-link ${selectedTab === 'userInfo' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">User Details</a>
                      </li>
                      {showPaymentTab ? 
                       <li className="nav-item" onClick={(e) => handleTabChange('subscriptionInfo', e)}>
                       <a className={`nav-link ${selectedTab === 'subscriptionInfo' ? 'active' : ''}`} style={{ borderBottom: '0px' }} href="#">Payments</a>
                     </li> : ''  
                    }
                    </ul>
                  </div>
                  {/* Conditional rendering based on selected tab */}
                  {selectedTab === 'userInfo' && (
                    <div>
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                <h1>{showPaymentTab}</h1>
                                  <div className="card-body tab-data-model">
                                    <div style={{ overflow: 'auto' }}>
                                      <UserInfo userDetails={userDetails}  />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTab === 'subscriptionInfo' && showPaymentTab && (
                    <div >
                      <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                        <div className="content-wraper-area">
                          <div className="dashboard-area">
                            <div className="container-fluid">
                              <div className="col-12">
                                <div className="card">
                                  <div className="card-body tab-data-model">
                                    <div style={{ overflow: 'auto' }}>
                                      <SubscriptionDetails userDetails={userDetails}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}
export default Profile;